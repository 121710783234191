<div class="content content-fixed py-5">
  <div class="container pd-x-0 pd-lg-x-10 pd-xl-x-0">
    <div class="container">
      <form #userForm="ngForm" name="form" (ngSubmit)="userForm.form.valid && onCreateUpdateCustomizedPackage()"
        novalidate>
        <div class="row">
          <div class="col-sm-2 col-md-2">
            <mat-form-field class="form-field" appearance="outline">
              <mat-label>Package Code</mat-label>
              <input [(ngModel)]="package.package_code" name="package_code" #package_code="ngModel" matInput
                autocomplete="off" required />
              <mat-error *ngIf="package_code.touched && package_code.errors?.required">
                Package code is <strong>required</strong>
              </mat-error>
            </mat-form-field>
          </div>
          <div class="col-sm-1 col-md-1">
            <mat-form-field class="form-field" appearance="outline">
              <mat-label>Nights</mat-label>
              <mat-select (selectionChange)="onNightChange()" [(ngModel)]="package.nights" name="nights"
                #nights="ngModel" matInput autocomplete="off" required>
                <mat-option *ngFor="let night of [].constructor(8); let i = index" [value]="i+1">{{i+1}} N</mat-option>
              </mat-select>
              <mat-error *ngIf="nights.touched && nights.errors?.required">
                Nights is <strong>required</strong>
              </mat-error>
            </mat-form-field>
          </div>

          <div class="col-sm-2 col-md-2">
            <mat-form-field class="form-field" appearance="outline">
              <mat-label>No. of location</mat-label>
              <mat-select (selectionChange)="onLocationChange()" [(ngModel)]="package.locations" name="locations"
                #locations="ngModel" matInput autocomplete="off" required>
                <mat-option *ngFor="let location of [].constructor(20); let i = index" [value]="i+1">{{i+1}}</mat-option>
              </mat-select>
              <mat-error *ngIf="locations.touched && locations.errors?.required">
                Location is <strong>required</strong>
              </mat-error>
            </mat-form-field>
          </div>
        </div>
        <div *ngIf="package.locations > 0" class="mb-3">
        <h3 class="mb-4 heading">Hotels / Resorts used in the Itinerary</h3>
          <div class="row" *ngFor="let location of package.location_data; let i = index">
            <div class="col-sm-2 col-md-2">
              <mat-form-field class="form-field" appearance="outline">
                <mat-label>location</mat-label>
                <mat-select matInput (selectionChange)="onLocationValueChange(i, 'location', $event.value)" [value]="package.location_data[i]['each_location']">
                  <mat-option *ngFor="let location of locations_data;" [value]="location.location_name">{{location.location_name}}</mat-option>
                </mat-select>
              </mat-form-field>
            </div>

            <div class="col-sm-1 col-md-1">
              <mat-form-field class="form-field" appearance="outline">
                <mat-label>Night</mat-label>
                <mat-select matInput (selectionChange)="onLocationValueChange(i, 'night', $event.value)" [value]="package.location_data[i]['each_night']">
                  <mat-option *ngFor="let location of [].constructor(20); let j = index" [value]="j+1">{{j+1}}</mat-option>
                </mat-select>
              </mat-form-field>
            </div>

            <div class="col-sm-2 col-md-2">
              <mat-form-field class="form-field" appearance="outline">
                <mat-label>Luxury Hotels</mat-label>
                <input
                  matInput
                  (paste)="onLocationValueChange(i, 'luxury', $event.target.value)"
                  (keyup)="onLocationValueChange(i, 'luxury', $event.target.value)"
                  [value]="package.location_data[i]['each_luxury']"
                />
              </mat-form-field>
            </div>

            <div class="col-sm-2 col-md-2">
              <mat-form-field class="form-field" appearance="outline">
                <mat-label>Deluxe Hotels</mat-label>
                <input
                  matInput
                  (paste)="onLocationValueChange(i, 'deluxe', $event.target.value)"
                  (keyup)="onLocationValueChange(i, 'deluxe', $event.target.value)"
                  [value]="package.location_data[i]['each_deluxe']"
                />
              </mat-form-field>
            </div>

            <div class="col-sm-2 col-md-2">
              <mat-form-field class="form-field" appearance="outline">
                <mat-label>Standard Hotels</mat-label>
                <input
                  matInput
                  (paste)="onLocationValueChange(i, 'standard', $event.target.value)"
                  (keyup)="onLocationValueChange(i, 'standard', $event.target.value)"
                  [value]="package.location_data[i]['each_standard']"
                />
              </mat-form-field>
            </div>

            <div class="col-sm-2 col-md-2">
              <mat-form-field class="form-field" appearance="outline">
                <mat-label>Budget Hotels</mat-label>
                <input
                  matInput
                  (paste)="onLocationValueChange(i, 'budget', $event.target.value)"
                  (keyup)="onLocationValueChange(i, 'budget', $event.target.value)"
                  [value]="package.location_data[i]['each_budget']"
                />
              </mat-form-field>
            </div>
          </div>
        </div>
        <div class="itinerary_container mb-4">
          <h3 class="heading">Itinerary</h3>
        <div class="row mb-3" *ngFor="let item of package.itineraries let i = index">
          <div class="col-sm-5 col-md-5 mb-4">
            <mat-chip-list >
              <mat-chip color="primary" selected>Day: {{i+1}}</mat-chip>
            </mat-chip-list>
          </div>

          <div class="col-sm-12 col-md-12">
          <div class="row">
            <div class="col-sm-7 col-md-7">
              <mat-form-field class="form-field" appearance="outline">
                <mat-label>Highlight</mat-label>
                <input [value]="package.itineraries[i]['itinerary_high_light']"
                  (keyup)="onChangeItierary('itinerary_high_light', i, $event.target.value)"
                  (paste)="onChangeItierary('itinerary_high_light', i, $event.target.value)" matInput autocomplete="off" />
              </mat-form-field>
            </div>
            <div class="col-sm-5 col-md-5">
              <mat-form-field class="form-field" appearance="outline">
                <mat-label>Title</mat-label>
                <input [value]="package.itineraries[i]['itinerary_title']" (keyup)="onChangeItierary('itinerary_title', i, $event.target.value)"
                  (paste)="onChangeItierary('itinerary_title', i, $event.target.value)" matInput autocomplete="off" />
              </mat-form-field>
            </div>
            <div class="col-sm-12 col-md-12">
            <mat-form-field class="form-field" appearance="outline">
              <mat-label>Itinerary</mat-label>
              <textarea matInput rows="7" [value]="package.itineraries[i]['itinerary_description']"
                (keyup)="onChangeItierary('itinerary', i, $event.target.value)"
                (paste)="onChangeItierary('itinerary', i, $event.target.value)">
                </textarea>
            </mat-form-field>
            </div>

            <div class="col-md-9 mb-4">
              <div class="row d-flex">
                <div class="col-sm-3 col-md-3" *ngIf="package.id > 0">
                  <div class="img">
                    <input type="file" (change)="onSelectProfileImage(i, 'image1', $event)" name="image[]" #fileInput1 />
                    <div (click)="fileInput1.click()" *ngIf="!package.itineraries[i]['image1'][0]" class="sliderImg">
                      <mat-icon mat-list-icon class="upload-image">add_a_photo</mat-icon>
                    </div>
                    <img (click)="fileInput1.click()" *ngIf="package.itineraries[i]['image1'][0]" src="{{endpoint.apiUrl}}{{package.itineraries[i]['image1'] ? package.itineraries[i]['image1'] : package.itineraries[i]['image1'][0].path}}" class="sliderImg" />
                    <a *ngIf="package.itineraries[i]['image1'][0]" mat-icon-button class="remove">
                      <mat-icon (click)="removeProfileImage(i, 'image1')" color="warn">delete</mat-icon>
                    </a>
                  </div>
                </div>

                <div class="col-sm-3 col-md-3" *ngIf="package.id > 0">
                  <div class="img">
                    <input type="file" (change)="onSelectProfileImage(i, 'image2', $event)" name="image[]" #fileInput2 />
                    <div (click)="fileInput2.click()" *ngIf="!package.itineraries[i]['image2'][0]" class="sliderImg">
                      <mat-icon mat-list-icon class="upload-image">add_a_photo</mat-icon>
                    </div>
                    <img (click)="fileInput2.click()" *ngIf="package.itineraries[i]['image2'][0]" src="{{endpoint.apiUrl}}{{package.itineraries[i]['image2'] ? package.itineraries[i]['image2'] : package.itineraries[i]['image2'][0].path}}" class="sliderImg" />
                    <a *ngIf="package.itineraries[i]['image2'][0]" mat-icon-button class="remove">
                      <mat-icon (click)="removeProfileImage(i, 'image2')" color="warn">delete</mat-icon>
                    </a>
                  </div>
                </div>

                <div class="col-sm-3 col-md-3" *ngIf="package.id > 0">
                  <div class="img">
                    <input type="file" (change)="onSelectProfileImage(i, 'image3', $event)" name="image[]" #fileInput3 />
                    <div (click)="fileInput3.click()" *ngIf="!package.itineraries[i]['image3'][0]" class="sliderImg">
                      <mat-icon mat-list-icon class="upload-image">add_a_photo</mat-icon>
                    </div>
                    <img (click)="fileInput3.click()" *ngIf="package.itineraries[i]['image3'][0]" src="{{endpoint.apiUrl}}{{package.itineraries[i]['image3'] ? package.itineraries[i]['image3'] : package.itineraries[i]['image3'][0].path}}" class="sliderImg" />
                    <a *ngIf="package.itineraries[i]['image3'][0]" mat-icon-button  class="remove">
                      <mat-icon (click)="removeProfileImage(i, 'image3')" color="warn">delete</mat-icon>
                    </a>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-sm-12 col-md-12">
              <mat-form-field class="form-field" appearance="outline">
                <mat-label>Itinerary sub title</mat-label>
                <textarea matInput rows="7" [value]="package.itineraries[i]['itinerary_sub_title']"
                  (keyup)="onChangeItierary('itinerary_sub_title', i, $event.target.value)"
                  (paste)="onChangeItierary('itinerary_sub_title', i, $event.target.value)">
                  </textarea>
              </mat-form-field>
              </div>

          </div>
          </div>
          <mat-divider></mat-divider>
        </div>
      </div>
      <section class="mb-4">
        <h3 class="mb-3 heading">Inclusions / Exclusions</h3>
        <div class="row">
          <div class="col-sm-6 col-md-6">
            <mat-form-field class="form-field" appearance="outline">
              <mat-label>Inclusions</mat-label>
              <textarea rows="10" matInput [(ngModel)]="package.inclusions" name="inclusions" #inclusions="ngModel"></textarea>
            </mat-form-field>
          </div>

          <div class="col-sm-6 col-md-6">
            <mat-form-field class="form-field" appearance="outline">
              <mat-label>Exclusions</mat-label>
              <textarea rows="10" matInput [(ngModel)]="package.exclusions" name="exclusions" #exclusions="ngModel"></textarea>
            </mat-form-field>
          </div>
        </div>
      </section>

        <div class="row">
          <div class="col-sm-12 col-md-12">
            <h3 class="mb-3 heading">Package Cost</h3>
          <div class="card mg-b-10">
            <div class="table-responsive">
              <table class="table table-dashboard mg-b-0" id="export-table">
                <tr>
                  <th>Category</th>
                  <th>Cost Per Person</th>
                  <th>Extra Adult</th>
                  <th>Child with Bed</th>
                  <th>Child without Bed</th>
                  <th>Remove</th>
                </tr>
                <tr *ngFor="let package_cost of package.package_costs; let i = index">
                  <td>
                    <mat-form-field appearance="outline">
                      <input
                        matInput
                        (paste)="onCostaRateEvent(i, 'category', $event.target.value)"
                        (keyup)="onCostaRateEvent(i, 'category', $event.target.value)"
                        [value]="package.package_costs[i]['category']"
                      />
                    </mat-form-field>
                  </td>
                  <td>
                    <mat-form-field appearance="outline">
                      <input
                        matInput
                        type="number"
                        (paste)="onCostaRateEvent(i, 'per_person', $event.target.value)"
                        (keyup)="onCostaRateEvent(i, 'per_person', $event.target.value)"
                        [value]="package.package_costs[i]['cost_per_person']"
                      />
                    </mat-form-field>
                  </td>
                  <td>
                    <mat-form-field appearance="outline">
                      <input
                        matInput
                        type="number"
                        (paste)="onCostaRateEvent(i, 'extra_person', $event.target.value)"
                        (keyup)="onCostaRateEvent(i, 'extra_person', $event.target.value)"
                        [value]="package.package_costs[i]['extra_person']"
                      />
                    </mat-form-field>
                  </td>
                  <td>
                    <mat-form-field appearance="outline">
                      <input
                        matInput
                        type="number"
                        (paste)="onCostaRateEvent(i, 'cwb', $event.target.value)"
                        (keyup)="onCostaRateEvent(i, 'cwb', $event.target.value)"
                        [value]="package.package_costs[i]['child_with_bed']"
                      />
                    </mat-form-field>
                  </td>
                  <td>
                    <mat-form-field appearance="outline">
                      <input
                        matInput
                        type="number"
                        (paste)="onCostaRateEvent(i, 'cnb', $event.target.value)"
                        (keyup)="onCostaRateEvent(i, 'cnb', $event.target.value)"
                        [value]="package.package_costs[i]['child_without_bed']"
                      />
                    </mat-form-field>
                  </td>
                  <td>
                    <a (click)="onRemove(i)"><mat-icon color="warn">cancel</mat-icon></a>
                  </td>
                </tr>
                <tr>
                  <td colspan="6">
                    <a mat-icon-button color="primary" (click)="addPackageCost()"><mat-icon>add_circle</mat-icon></a>
                  </td>
                </tr>
              </table>
            </div>
          </div>
          </div>
        </div>

        <button [disabled]="!userForm.valid"  mat-raised-button color="primary">Submit</button>
      </form>
    </div>
  </div>
</div>
