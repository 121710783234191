export const environment = {
  production: true,
  CONTACT_EMAIL: 'info@mountainedge.in / mountainedgepb@gmail.com',
  CONTACT_ADDRESS: '1st Floor, Firdos Manzil, Near Clock Tower, Aberdeen Bazaar, Port Blair - 744101, Andaman Islands',
  ADDRESS_FIRST:'1st Floor, Firdos Manzil, Near Clock Tower, Aberdeen Bazaar,',
  ADDRESS_SECOND:'Port Blair - 744101, Andaman Islands',
  TEL_NO: '03192-233725 / 232228',
  MOB_NO: '+91 800-1221-800',
  CONTACT_WEBSITE: 'https://www.mountainedge.in',
  CRM_NAME: 'ME Group CRM',
  COMPANY_NAME: 'Mountain Edge Tours and Holidays Pvt. Ltd.',
  LOGO: 'assets/img/logo.png',
  INVOICE_LOGO:'assets/img/Mountain-Edge.png',
  DIRECTOR: 'Mr Prakash Kumar Raj',
  SORT_COMPANY_NAME: 'Mountain Edge',

  SERVICE_CONTACT_EMAIL: 'info@mountainedge.in / mountainedgepb@gmail.com',
  SERVICE_CONTACT_ADDRESS: 'Near postal and AHW colony, opposite rajasthan manch shadipur post, Port Blair south andaman nicobar island 744106',
  SERVICE_ADDRESS_FIRST:'Near postal and AHW colony, opposite rajasthan manch shadipur post,',
  SERVICE_ADDRESS_SECOND:'Port Blair south andaman nicobar island 744106',
  SERVICE_TEL_NO: '03192-233725 / 232228',
  SERVICE_MOB_NO: '+91 800-1221-800',
  SERVICE_CONTACT_WEBSITE: 'https://www.mountainedge.in',
  SERVICE_CRM_NAME: 'ME Group CRM',
  SERVICE_COMPANY_NAME: 'Mountain Edge Business Services',
  SERVICE_LOGO: 'assets/img/service_logo.png',
  SERVICE_INVOICE_LOGO:'assets/img/service_logo.png',
  SERVICE_DIRECTOR: 'Mr Prakash Kumar Raj',
  SERVICE_SORT_COMPANY_NAME: 'Mountain Edge',

  TRIP_ZOLO_INVOICE_LOGO:'assets/img/tripzolo-logo.png',
  TRIP_ZOLO_CIN_NO:'U6303MH2020PTC346749',
  TRIP_ZOLO_UAN:'assets/img/tripzolo-logo.png',
  TRIP_ZOLO_SAC_CODE:'998596',
  TRIP_ZOLO_GSTIN:'27AAICT1244J1Z8',
  TRIP_ZOLO_ADDRESS:'D-211, Eastern Business District, LBS Road, Bhandup (West), Mumbai - 400078',
  TRIP_ZOLO_TEL_NO: '022 46021672 | +91 7777-02-4800',
  TRIP_ZOLO: 'Tripzolo India Pvt. Ltd.',
  TRIP_ZOLO_EMAIL: 'contact@tripzolo.com | tripzoloindia@gmail.com',

  ANDAMAN_VACATION_INVOICE_LOGO:'assets/img/andaman-vacations-logo.png',
  ANDAMAN_VACATION_CIN_NO:'U6303MH2020PTC346749',
  ANDAMAN_VACATION_UAN:'assets/img/tripzolo-logo.png',
  ANDAMAN_VACATION_SAC_CODE:'998596',
  ANDAMAN_VACATION_GSTIN:'27AAICT1244J1Z8',
  ANDAMAN_VACATION_ADDRESS:'Near Postal & AHW Complex, Shadipur, Port Blair – 744101',
  AV_ADDRESS_FIRST:'Near Postal & AHW Complex, Shadipur',
  AV_ADDRESS_SECOND:'Port Blair – 744101, Andaman and Nicobar Islands',
  ANDAMAN_VACATION_MOB_NO: '+91 993-208-0880',
  ANDAMAN_VACATION: 'Andaman Vacations',
  ANDAMAN_VACATION_EMAIL: 'reservation@andaman.vacations',
};
