import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { SnackBarService } from 'src/services/alert/snack-bar.service';
import { AuthService } from 'src/services/auth-user/auth.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
    constructor(
      private router: Router,
      public authService: AuthService,
      public _snack_bar: SnackBarService,
  ) { }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const user = this.authService.getLogin();
    if ((JSON.parse(localStorage.getItem("trip-zolo-crm")) && JSON.parse(localStorage.getItem("trip-zolo-crm")).id > 0) || (user['_value'].id > 0 || user['_value'].includes() != [])) {
      const isAdmin = this.authService.isAdmin();
      const isSuperAdmin = this.authService.isSuperAdmin();
      const isCoordinator = this.authService.isCoordinator();
      const isDriver = this.authService.isDriver();
      const isOperation = this.authService.isOperation();
      const isAgent = this.authService.isAgent();
      const isGroundManager = this.authService.isGroundManager();
      const isGroundOperation = this.authService.isGroundOperation();

      if(isDriver){
        // this._snack_bar.error("Unauthorized Access.");
        // this.router.navigate(['drivers/tour-allocation']);
        return false;
      }
      if(isCoordinator){
        // this._snack_bar.error("Unauthorized Access.");
        // this.router.navigate(['coordinator']);
        return false;
      }

      if(isAgent){
        return false;
      }
      if(isGroundManager){
        return false;
      }

      if(isGroundOperation){
        return false;
      }

      if(!isAdmin && !isSuperAdmin && !isOperation){
        this._snack_bar.error("Unauthorized Access.");
        this.router.navigate(['operation/dashboard'])
        return false;
      }
      return true;
    }else{
      this.router.navigate(['/'], { queryParams: { returnUrl: state.url }});
        return false;
    }
  }
}
