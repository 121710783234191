import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './utility/header/header.component';
import { FooterComponent } from './utility/footer/footer.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SharingModule } from './utility/sharing-module/sharing-module.module';
import { LoginComponent } from './login/login.component';
import { HttpClientModule } from '@angular/common/http';
import { SidenavListComponent } from './utility/sidenav-list/sidenav-list.component';
import { ConfirmedListComponent } from './ground-manager/confirmed-list/confirmed-list.component';
import { GridFilesComponent } from './file-manage-dashboard/grid-files/grid-files.component';
import { CustomizePackagesComponent } from './file-manage-dashboard/customize-packages/customize-packages.component';
import { PackageDetailsComponent } from './file-manage-dashboard/customize-packages/package-details/package-details.component';
import { CustomizedPackageFormComponent } from './file-manage-dashboard/customized-package-form/customized-package-form.component';
import { SidebarComponent } from './utility/sidebar/sidebar.component';
import { MainLayoutComponent } from './utility/main-layout/main-layout.component';

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    LoginComponent,
    SidenavListComponent,
    ConfirmedListComponent,
    GridFilesComponent,
    CustomizePackagesComponent,
    PackageDetailsComponent,
    CustomizedPackageFormComponent,
    SidebarComponent,
    MainLayoutComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    SharingModule,
    HttpClientModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
