import { Component, OnInit } from '@angular/core';
import { SnackBarService } from 'src/services/alert/snack-bar.service';
import { AuthService } from 'src/services/auth-user/auth.service';
import { EndPointService } from 'src/services/end-point.service';

@Component({
  selector: 'app-customize-packages',
  templateUrl: './customize-packages.component.html',
  styleUrls: ['./customize-packages.component.css']
})
export class CustomizePackagesComponent implements OnInit {
  packages = [];
  nights = [];
  package_list = [];
  constructor(
    private endpoint: EndPointService,
    private _snack_bar: SnackBarService,
    public AuthService: AuthService
  ) { }

  ngOnInit(): void {
    this.getNight();
    this.getCustomizedPackages();
  }


  getNight(){
    this.endpoint.getData("admins/customised-package/night-list",{}).subscribe(
      (resp) => {
        if (resp.status && resp.message == 'success') {
          this.nights = resp.data;
          return;
        }
        this._snack_bar.error(resp.message);
      },
      (err) => {
        this._snack_bar.error("Error while fetching package.");
      }
    );
  }

  getCustomizedPackages(){
    this.endpoint.getData("admins/customised-package",{}).subscribe(
      (resp) => {
        if (resp.status && resp.message == 'success') {
          this.packages = resp.data;
          this.onTabChange(0);
          return;
        }
        this._snack_bar.error(resp.message);
      },
      (err) => {
        this._snack_bar.error("Error while fetching package.");
      }
    );
  }

  onTabChange(index){
    this.package_list = this.packages[this.nights[index]];
  }
}
