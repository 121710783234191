import { Component, OnInit } from '@angular/core';
import { SnackBarService } from 'src/services/alert/snack-bar.service';
import { EndPointService } from 'src/services/end-point.service';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.css']
})
export class ForgotPasswordComponent implements OnInit {
  forgotBtn: boolean = false;
  forgot_password = {
    email: ''
  }
  constructor(
    private endPoint: EndPointService,
    public _snack_bar: SnackBarService
  ) { }

  ngOnInit(): void {
  }

  sendForgotPasswordMail(){
    this.forgotBtn = true;
    this.endPoint.postData('users/forgot-password',this.forgot_password).subscribe(resp => {
      if (resp.status === true) {
        this._snack_bar.success(resp.message);
        this.forgotBtn = false;
        return;
      }
      this._snack_bar.error(resp.message);
      this.forgotBtn = false;
    },(error) => {
      this._snack_bar.error('Oops..! Error while forgot password.');
      this.forgotBtn = false;
    });
  }

}
