<div class="content content-fixed bd-b">
    <div class="container pd-x-0 pd-lg-x-10 pd-xl-x-0">
      <div class="d-sm-flex align-items-center justify-content-between">
        <div>
          <nav aria-label="breadcrumb">
            <ol class="breadcrumb breadcrumb-style1 mg-b-10">
              <li class="breadcrumb-item"><a>Customize Package - 2022 Off</a></li>
              <li class="breadcrumb-item active" aria-current="page">List</li>
            </ol>
          </nav>
          <h4 class="mg-b-0">Customize Packages - 2022 | Off Season</h4>
        </div>
      </div>
    </div><!-- container -->
  </div><!-- content -->

  <div class="content">
    <div class="container pd-x-0 pd-lg-x-10 pd-xl-x-0">
      <div class="row">
        <div class="col-lg-12">
            <mat-tab-group animationDuration="0ms" (selectedTabChange)="onTabChange($event.index)">
                <mat-tab label="{{night}} Nights / {{night+1}} Days" (chage)="ssadd(night)" *ngFor="let night of nights;let i = index">
                    <div class="row row-xs mg-b-25">
                        <div class="col-sm-4 col-md-4 col-lg-4 col-xl-3" *ngFor="let list of package_list">
                          <mat-card>
                              <mat-card-content>
                                  <!-- <img mat-card-image src="https://www.mountainedge.in/photoz/pkgImg/beachy_andaman.jpg" alt="Photo of a Shiba Inu"> -->
                                  <mat-card-title>{{list.package_code}}</mat-card-title>
                                  <mat-card-subtitle>Duration : {{list.nights}}N / {{list.nights+1}}D</mat-card-subtitle>
                              </mat-card-content>
                              <mat-card-actions>
                                <a mat-raised-button color="primary" [routerLink]="['/files/package-details',list.id]" >VIEW DETAILS</a>
                                <a mat-stroked-button *ngIf="AuthService.isSuperAdmin()" [routerLink]="['/admin/customized-package-form', list.id]"><mat-icon>edit</mat-icon>Edit</a>
                              </mat-card-actions>
                          </mat-card>
                        </div>
                    </div>
                </mat-tab>
              </mat-tab-group>
        </div>
      </div>
    </div>
  </div>
