<div class="content content-fixed">
  <div class="container pd-x-0 pd-lg-x-10 pd-xl-x-0">
    <button mat-button *ngIf="!attandance"><mat-icon><mat-spinner diameter="20" color="accent"></mat-spinner></mat-icon>Loading...</button>
    <div class="container" *ngIf="attandance">
      <div class="card-body">
        <div class="row">
          <div class="col-sm-3 col-md-3">
        <p>Mark attendance for today ({{todayDate | date: 'dd MMM, yyyy'}})</p>
        <p>You can mark your attendance for today. For any other day, please use the edit option below.</p>
        <button mat-raised-button (click)="onAttendance()" [disabled]="attandance_loader || attandance.status == 2">Check {{attandance.status == 1 ? 'out': 'in'}}<mat-icon *ngIf="attandance_loader"><mat-spinner diameter="20"></mat-spinner></mat-icon></button>
        <p>check in {{attandance.created_at | date: 'h:mm a'}}</p>
        <p *ngIf="attandance.status == 2">check out {{attandance.updated_at | date: 'h:mm a'}}</p>
        <p *ngIf="attandance.status == 2">Duration {{attandance.duration}}</p>
        <p>{{attandance.presents}} day(s) present in current month</p>
        </div>
        <div class="col-sm-9 col-md-9">
          <full-calendar #calendar [options]="calendarOptions"></full-calendar>
        </div>
        </div>
    </div>

    </div>
    </div>

    <!-- <button (click)="updateHeader()" id="updateHeader">Update header</button>
<button (click)="updateEvents()" id="updateEvents">Update events</button> -->

    </div>
