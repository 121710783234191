import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SnackBarService } from 'src/services/alert/snack-bar.service';
import { EndPointService } from 'src/services/end-point.service';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.css']
})
export class ResetPasswordComponent implements OnInit {

  hide = true;
  resetBtn: boolean = false;
  valid_secure_key: boolean = false;
  reset_pasword_error_msg: any;
  secure_key: any;
  resetPassword = {
    new_password: '',
    confirm_password: ''
  }

  constructor(
    private route:Router,
    private router: ActivatedRoute,
    private endPoint:EndPointService,
    public _snack_bar: SnackBarService
  ) { }

  ngOnInit(): void {
    this.secure_key = this.router.snapshot.paramMap.get('secure_key')
    this.secureKeyValidate({secure_key:this.secure_key});
  }

  secureKeyValidate(secure_key){
    this.endPoint.postData('users/forgot-password/secure-key', secure_key).subscribe(resp => {
      if (resp.status && resp.message == 'success') {
        this.valid_secure_key = true;
        return;
      }
        this.valid_secure_key = false;
        this.reset_pasword_error_msg = resp.message;
        this._snack_bar.error(resp.message);
    },(error) => {
      this.valid_secure_key = false;
      this._snack_bar.error("Oops..! Error while validation secure key.");
    });
  }

  onResetPassword(){
    this.resetBtn = true;
    this.resetPassword["secure_key"] = this.secure_key;
    this.endPoint.postData('users/forgot-password/reset-password', this.resetPassword).subscribe(resp => {
      if (resp.status === true) {
        this.resetBtn = false;
        this._snack_bar.success(resp.message);
        this.route.navigate(['/']);
        return;
      }
        this.resetBtn = false;
        this.reset_pasword_error_msg = resp.message;
        this._snack_bar.error(resp.message);
    },(error) => {
      this.resetBtn = false;
      this._snack_bar.error("Oops..! Error while reset password.");
    });
  }
}
