<div style="background: url(../../../../assets/img/andaman-islands.jpg) no-repeat center;
   height: 260px;"></div>
<div class="content bd-b" *ngIf="package">
   <div class="container pd-x-0 pd-lg-x-10 pd-xl-x-0">
      <div class="d-sm-flex align-items-center justify-content-between row">
         <div class="col-md-9">
            <nav aria-label="breadcrumb" class="hides">
               <ol class="breadcrumb breadcrumb-style1 mg-b-10">
                  <li class="breadcrumb-item"><a href="#">Customize Packages - 2022 Off</a></li>
                  <li class="breadcrumb-item"><a href="#">Package</a></li>
                  <li class="breadcrumb-item">{{package.package_code}}</li>
                  <li class="breadcrumb-item active" aria-current="page">Package Details</li>
               </ol>
            </nav>
            <h1 class="mg-b-0">Customized Package for Andaman Islands | {{package.nights}} Night & {{package.nights + 1}} Days | {{package.package_code}}</h1>
         </div>
      </div>
   </div>
   <!-- container -->
</div>
<!-- content -->
<div class="content" *ngIf="package" style="background: #fff;">
   <div class="container pd-x-0 pd-lg-x-10 pd-xl-x-0">
      <table border="0" cellspacing="0" cellpadding="0" style="margin: auto; ">
         <thead>
            <tr>
               <td>
                  <div class="header-space"></div>
               </td>
            </tr>
         </thead>
         <tbody>
            <tr>
               <td style="padding-top: 0px; padding-bottom: 10px;">
                  <div class="media d-block d-lg-flex">
                     <div class="row">
                        <div class="col-md-12 col-sm-12 col-12">
                     <div class="media-body">
                        <div class="highlights" style="margin-bottom: 1rem; padding: 1rem 0;">
                          <p class="head"><span style="font-weight: bold;"> Tour Code : </span> <span>{{package.package_code}}</span></p>
                           <p class="head" style='margin: 0px 0px 10px; color: rgb(0, 0, 0); font-weight: normal'>
                              <span style="font-weight: bold;color: #d34829">Tour Highlights : </span> <span *ngFor="let location of package.location_data" class="locations">{{location['each_location']}} <span style="font-weight: normal;">({{location['each_night']}} Nights)</span> <span class="bar">|</span> </span>
                           </p>
                           <p style='margin: 0px 0px 5px;' *ngFor="let itinerary of package.itineraries; let i = index">
                            Day {{i+1}} : {{itinerary.itinerary_high_light}}
                           </p>
                        </div>

                        <h3 class="title" style="color: #d34829">Detailed Itinerary</h3>
                        <div class="timeline-group tx-13 my-4">
                        <div *ngFor="let itinerary of package.itineraries; let i = index" style="page-break-inside: avoid !important;" class="days">
                           <div class="timeline-label">Day {{i+1}}</div>
                           <div class="timeline-item">
                              <div class="timeline-body">
                                 <h5 class="mg-b-0">{{itinerary.itinerary_title | uppercase}}</h5>
                                 <div class="mb-10 itinerary">
                                    <p>{{itinerary.itinerary_description}}</p>
                                 </div>
                                 <div class="row row-xs images mb-3">
                                    <div class="col-md-3" *ngIf="itinerary.image1">
                                       <img src="{{endpoint.apiUrl}}{{itinerary.image1}}" style="height: 130px;" class="img-fluid" alt="">
                                    </div>

                                    <div class="col-md-3" *ngIf="itinerary.image2">
                                      <img src="{{endpoint.apiUrl}}{{itinerary.image2}}" style="height: 130px;" class="img-fluid" alt="">
                                   </div>

                                   <div class="col-md-3" *ngIf="itinerary.image3">
                                    <img src="{{endpoint.apiUrl}}{{itinerary.image3}}" style="height: 130px;" class="img-fluid" alt="">
                                 </div>
                                 </div>
                                 <p [innerHtml]="itinerary.itinerary_sub_title"></p>
                              </div>
                           </div>
                           </div>
                        </div>

                        <!-- <div class="break"></div> -->
                        <div class="pcost " style='margin: 20px 0px 10px; padding: 20px 0 15px; border-top: 1px dashed #aaa;'>
                           <p style='margin: 0px 0px 20px; color: #d34829; font-size: 13pt; font-weight: bold;'>Package Cost :</p>
                           <table cellspacing="0" cellpadding="10" class="cost">
                              <tr bgcolor="#ededed">
                                 <td><b>Category </b> <span>(Hotel Wise)</span></td>
                                 <td><b>Cost Per Person </b> <span>On Twin Sharing </span> </td>
                                 <td><b>Extra Adult </b> <span>(12 years Above)</span></td>
                                 <td><b>Child with Bed </b> <span>(5 to below 12 years)</span></td>
                                 <td><b>Child without Bed </b> <span>(5 to below 12 years)</span></td>
                              </tr>
                              <tr *ngFor="let package_cost of package.package_costs">
                                 <td>{{package_cost.category}}</td>
                                 <td>&#8377; {{package_cost.cost_per_person}}</td>
                                 <td>&#8377; {{package_cost.extra_person}}</td>
                                 <td>&#8377; {{package_cost.child_with_bed}}</td>
                                 <td>&#8377; {{package_cost.child_without_bed}}</td>
                              </tr>
                           </table>
                           <div>
                              <p style='margin: 5px 0px 10px;  font-style: italic;'>Above Cost is excluding GST and Hotel Detailed at the end of the Document</p>
                           </div>
                        </div>
                        <!-- <div class="break"></div> -->
                        <div style="page-break-inside: avoid !important">
                           <div class="inclusions" style="margin-bottom: 20px;">
                              <h6 class="mg-b-0">Above Package Includes</h6>

                              <div  *ngFor="let inclusion of package.inclusions.split('||'); let i = index">
                                <p>{{i+1}}.&nbsp; {{inclusion}}</p>
                              </div>
                           </div>
                           <div class="exclusions" style="margin-bottom: 20px;">
                              <h6 class="mg-b-0">Above Package Excludes</h6>
                              <div  *ngFor="let exclusion of package.exclusions.split('||'); let i = index">
                                <p>{{i+1}}.&nbsp; {{exclusion}}</p>
                              </div>
                           </div>
                        </div>
                        <div class="page pcost" style="page-break-inside: avoid !important; margin-top: 30px;">
                           <p style='margin: 20px 0px 10px; color: #d34829; font-size: 13pt; font-weight: bold;'>Hotel / Resorts used in the Itinerary </p>
                           <table cellspacing="0" cellpadding="10" class="cost">
                              <tr bgcolor="#ededed">
                                 <td><b>Locations at </b> <span>(Hotel Wise)</span></td>
                                 <td><b>Luxury </b> <span>(4 Star Deluxe)</span> </td>
                                 <td><b>Deluxe </b> <span>(4 Star Equ)</span></td>
                                 <td><b>Standard </b> <span>(3 Star)</span></td>
                                 <td><b>Budget </b> <span>(2 Star Equ)</span></td>
                              </tr>
                              <tr *ngFor="let location of package.location_data">
                                 <td>{{location.each_location}}</td>
                                 <td>{{location.each_luxury}}</td>
                                 <td>{{location.each_deluxe}}</td>
                                 <td>{{location.each_standard}}</td>
                                 <td>{{location.each_budget}}</td>
                              </tr>
                           </table>
                        </div>
                        <nav class="nav nav-row mg-t-15">
                            <button class="hides" mat-raised-button color="primary" (click)="onPrint()">Print Itinerary</button>
                        </nav>
                     </div>
                     </div>
                     <!-- media-body -->
                     <!-- <div class="col-md-4 col-12">
                     <div class="profile-sidebar mx-wd-300 mg-t-40 mg-lg-t-0 pd-lg-l-25">
                        <mat-list role="list">
                           <mat-card *ngFor="let night of nights">
                              <mat-card-content>
                                 <div mat-subheader>{{night}} Nights / {{night + 1}} Days Packages</div>
                                 <mat-list-item role="listitem" *ngFor="let package of packages[night]">
                                    <div mat-line><a [routerLink]="['/files/package-details/',package.id]" routerLinkActive="router-link-active" >{{package.package_code}}</a></div>
                                    <div mat-line>{{night}} Nights / {{night + 1}} Days</div>
                                 </mat-list-item>
                              </mat-card-content>
                           </mat-card>
                        </mat-list>
                     </div>
                  </div> -->
                     </div>
                  </div>
               </td>
            </tr>
         </tbody>
         <tfoot>
            <tr>
               <td>
                <div class="page-footer">
                  <div style="display: flex; justify-content: space-between; border-top: 5px solid #f49624;">
                     <div style="display: flex; justify-content: space-between; flex-direction: column; padding: 5px 10px 5px;">
                        <p style='margin: 5px 0 5px 10px; color: rgb(50, 56, 72); font-family: "Arial", sans-serif; font-size: 16px; font-weight: bold;'>Mountain Edge Tours & Holidays Pvt. Ltd </p>
                        <p style='margin: 0px 10px 5px; color: rgb(50, 56, 72); font-family: "Arial", sans-serif; font-size: 12px; line-height: 16px;'>Opp. Rajastha Manch, Shadipur, Port Blair<br>
                           <b>Email :</b> info@mountainedge.in, mountainedgepb@gmail.com &nbsp; &nbsp;<b>|</b>&nbsp; <b>Web :</b> www.mountainedge.in
                        </p>
                        <p style='margin: 0 10px 10px; color: rgb(50, 56, 72); font-family: "Arial", sans-serif; font-size: 16px; font-weight: bold;'>+91 800 123 2000</p>
                     </div>
                     <img style="padding: 20px 10px 32px;" src="https://mountainedge.in/images-new/connectindialogo.png" height="40">
                     <!-- <p id="pageCount">Page </p> -->
                  </div>
               </div>
               </td>
            </tr>
         </tfoot>
      </table>
   </div>
   <!-- container -->
</div>
<!-- content -->
