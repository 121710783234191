import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SnackBarService } from 'src/services/alert/snack-bar.service';
import { AuthService } from 'src/services/auth-user/auth.service';
import { EndPointService } from 'src/services/end-point.service';

@Component({
  selector: 'app-customized-package-form',
  templateUrl: './customized-package-form.component.html',
  styleUrls: ['./customized-package-form.component.css']
})
export class CustomizedPackageFormComponent implements OnInit {
  inclusions = [
    'Air-Conditioned Hotel Accommodation for 3 Night at specified locations.',
    'Return airport / harbour transfers by Private AC car as per the itinerary.',
    'Specified Sightseeing / transfers in Private AC Car as per the itinerary.',
    'All entry permits / tickets, ferry tickets and permits wherever applicable as per the itinerary.',
    'Breakfast for 03 occasions at respective Hotel.',
    'Assistance at all arrival and departure points.'
  ];

  exclusion = [
    '5% GST on the total Invoice.',
    'Personal Expense Such Telephonic calls, Camera, Laundry charges, Internet, Tips, etc.',
    'Any Major Meals i.e. Lunch or Dinner if not specified in inclusion list.'
  ]

  package = {
    id: 0,
    package_code: '',
    nights:  0,
    locations: 0,
    inclusions: this.inclusions.join('||\n').toString(),
    exclusions: this.exclusion.join('||\n').toString(),
    location_data: [],
    package_costs: [],
    itineraries: []
  }
  locations_data = [];

  constructor(
    private endpoint: EndPointService,
    private _snack_bar: SnackBarService,
    private active_route: ActivatedRoute,
    private router: Router,
    public AuthService: AuthService
  ) { }

  ngOnInit(): void {
    this.package.id = Number(this.active_route.snapshot.paramMap.get("id"));
    if(this.package.id > 0){
      this.getCustomizedPackage();
    }

    this.getLocations();
  }

  onCreateUpdateCustomizedPackage(){
    this.endpoint.postData("admins/customised-package/create-update", this.package).subscribe(
      (resp) => {
        if (resp.status && resp.message == 'success') {
          this._snack_bar.success(resp.message);
          this.router.navigate(['/admin/customized-package-form',resp.data.id]);
          return;
        }
        this._snack_bar.error(resp.message);
        // this.userBtn = false;
      },
      (err) => {
        // this.userBtn = false;
        this._snack_bar.error("Error while create or update package.");
      }
    );
  }

  getCustomizedPackage(){
    this.endpoint.getData("admins/"+this.package.id+"/customised-package",{}).subscribe(
      (resp) => {
        if (resp.status && resp.message == 'success') {
          this.package = resp.data;
          return;
        }
        this._snack_bar.error(resp.message);
      },
      (err) => {
        this._snack_bar.error("Error while fetching package.");
      }
    );
  }

  onNightChange(){
    let nights = this.package.nights + 1;
    let ititnerary_len = this.package.itineraries.length;
    if (nights > ititnerary_len) {
      for (let i = ititnerary_len; i < nights; ++ i) {
        let itinerary = {
          itinerary_high_light: '',
          itinerary_title: '',
          itinerary_sub_title: '',
          itinerary_description: '',
          image1: '',
          image2: '',
          image3: '',
        }
        this.package.itineraries.push(itinerary);
      }
    }else{
      for(let i = ititnerary_len; i > nights; --i){
        this.package.itineraries.pop();
      }
    }
  }

  onLocationChange(){
    let locations = this.package.locations;
    let ititnerary_len =  this.package.location_data.length;
    if (locations > ititnerary_len) {
      for (let i = ititnerary_len; i < locations; ++ i) {
        let location = {
          each_location: '',
          each_night: 0,
          each_luxury: '',
          each_deluxe: '',
          each_standard: '',
          each_budget: '',
        }
        this.package.location_data.push(location);
      }
    }else{
      for(let i = ititnerary_len; i > locations; --i){
        this.package.location_data.pop();
      }
    }
  }

  onChangeItierary(type, index, value){
    switch(type){
      case 'itinerary':
      this.package.itineraries[index]['itinerary_description'] = value;
      break;
      case 'itinerary_high_light':
      this.package.itineraries[index]['itinerary_high_light'] = value;
      break;
      case 'itinerary_title':
      this.package.itineraries[index]['itinerary_title'] = value;
      break;
      case 'itinerary_sub_title':
      this.package.itineraries[index]['itinerary_sub_title'] = value;
      break;
    }
  }

  getLocations(){
    this.endpoint.getData("place/state/1/locations",{}).subscribe(
      (resp) => {
        if (resp.status && resp.message == 'success') {
          this.locations_data = resp.data;
          return;
        }
        this._snack_bar.error(resp.message);
      },
      (err) => {
        this._snack_bar.error("Error while fetching location.");
      }
    );
  }

  onLocationValueChange(index, type, value){
    switch(type){
      case 'location':
        this.package.location_data[index]['each_location'] = value;
      break;
      case 'night':
        this.package.location_data[index]['each_night'] = Number(value);
      break;
      case 'luxury':
        this.package.location_data[index]['each_luxury'] = value;
      break;
      case 'deluxe':
        this.package.location_data[index]['each_deluxe'] = value;
      break;
      case 'standard':
        this.package.location_data[index]['each_standard'] = value;
      break;
      case 'budget':
        this.package.location_data[index]['each_budget'] = value;
      break;
    }
    return;
  }

  addPackageCost(){
    let package_cost = {
      category: '',
      cost_per_person: 0,
      extra_person: 0,
      child_with_bed: 0,
      child_without_bed: 0,
    }

    this.package.package_costs.push(package_cost);
  }

  onRemove(index){
    this.package.package_costs.splice(index, 1);
  }

  onCostaRateEvent(index, type, value){
    switch(type){
      case 'category':
        this.package.package_costs[index]['category'] = value;
      break;
      case 'per_person':
        this.package.package_costs[index]['cost_per_person'] = Number(value);
      break;
      case 'extra_person':
        this.package.package_costs[index]['extra_person'] = Number(value);
      break;
      case 'cwb':
        this.package.package_costs[index]['child_with_bed'] = Number(value);
      break;
      case 'cnb':
        this.package.package_costs[index]['child_without_bed'] = Number(value);
      break;
    }
  }

  selected_img: any;
  imageurls = [];
  onSelectProfileImage(index, image, event) {
    this.selected_img = event.target.files;
    if (this.selected_img && this.selected_img[0]) {
      var reader = new FileReader();
        reader.onload = (event: any) => {
          this.package.itineraries[index][image] = [{ path: event.target.result}];
          this.uploadImage(this.selected_img, index, image)
        }
      }
      reader.readAsDataURL(this.selected_img[0]);
  }

  removeProfileImage(index, image){
    this.package.itineraries[index][image] = "";
    this.removeItineraryImage(index, image);
  }

  removeItineraryImage(index, image){
    let data = {
      index: index,
      image: image
    }
    this.endpoint.postData("customised-packages/"+this.package.id+"/itinerary-image/delete", data).subscribe(
      (resp) => {
        if (resp.status && resp.message == 'success') {
          this.onCreateUpdateCustomizedPackage();
          return;
        }
        this._snack_bar.error(resp.message);
      },
      (err) => {
        this._snack_bar.error("Error while removing.");
      }
    );
  }

  uploadImage(selected_img, index, image){
    const formData = new FormData();
    formData.append("itinerary_image", selected_img[0], selected_img[0].name);
    formData.append("index", index);
    formData.append("image", image);
    this.endpoint.postData("customised-packages/"+this.package.id+"/itinerary-image", formData).subscribe(
      (resp) => {
        if (resp.status && resp.message == 'success') {
          this.package.itineraries[index][image] = resp.data;
          this.onCreateUpdateCustomizedPackage();
          return;
        }
        this._snack_bar.error(resp.message);
      },
      (err) => {
        this._snack_bar.error("Error while uploading.");
      }
    );
  }
}
