<div *ngIf="visible_header" class="aside aside-fixed">
  <!-- (mouseenter)="mouseEnterLeave(false)" (mouseleave)="mouseEnterLeave(true)" -->
    <div class="aside-header">
        <img src="{{contact_details.LOGO}}" class="logoImage">
      <!-- <a href="../../index.html" class="aside-logo">dash<span>forge</span></a>  -->
      <!-- <a href="" class="aside-menu-link">
        <i data-feather="menu"></i>
        <i data-feather="x"></i>
      </a> -->
      <a mat-icon-button (click)="toggleMinimize()"><mat-icon>{{isMinimized ? 'menu':'close'}}</mat-icon></a>
      <!-- <a mat-icon-button (click)="toggleMinimize()" class="aside-menu-link"><mat-icon>menu</mat-icon></a> -->
    </div>
    <div class="aside-body ps--active-y" id="asideY">
        <div class="aside-loggedin">
            <div class="avatar"><img src="{{(logged_in_user && logged_in_user.avatar)? endpoint.apiUrl+''+logged_in_user.avatar :'./assets/avatar/avatar.png'}}" class="rounded-circle" alt=""></div>
            <!-- <div class="d-flex align-items-center justify-content-start">
              <div class="aside-alert-link">
                <a href="" class="new" data-toggle="tooltip" title="You have 2 unread messages"><i data-feather="message-square"></i></a>
                <a href="" class="new" data-toggle="tooltip" title="You have 4 new notifications"><i data-feather="bell"></i></a>
                <a href="" data-toggle="tooltip" title="Sign out"><i data-feather="log-out"></i></a>
              </div>
            </div> -->
            <div class="aside-loggedin-user">
                <h6 class="user tx-medium mg-b-0">{{(logged_in_user && logged_in_user.name) ? logged_in_user.name : 'User'}}</h6>
                <!-- <a href="#loggedinMenu" class="d-flex align-items-center justify-content-between mg-b-2" data-toggle="collapse">
                    <h6 class="user tx-medium mg-b-0">{{(logged_in_user && logged_in_user.name) ? logged_in_user.name : 'User'}}</h6>
                    <i data-feather="chevron-down"></i>
                </a> -->
                <p class="tx-color-03 mg-b-0 email">{{(logged_in_user && logged_in_user.email) ? logged_in_user.email : ''}}</p>
            </div>
            <!-- <div class="collapse" id="loggedinMenu">
              <ul class="nav nav-aside mg-b-0">
                <li class="nav-item"><a href="" class="nav-link"><i data-feather="edit"></i> <span>Edit Profile</span></a></li>
                <li class="nav-item"><a href="" class="nav-link"><i data-feather="user"></i> <span>View Profile</span></a></li>
                <li class="nav-item"><a href="" class="nav-link"><i data-feather="settings"></i> <span>Account Settings</span></a></li>
                <li class="nav-item"><a href="" class="nav-link"><i data-feather="help-circle"></i> <span>Help Center</span></a></li>
                <li class="nav-item"><a href="" class="nav-link"><i data-feather="log-out"></i> <span>Sign Out</span></a></li>
              </ul>
            </div> -->
          </div><!-- aside-loggedin -->
        <!-- <div class="sidenav-toggle-btn onDesk" (click)="toggleMinimize()">
             <a mat-icon-button><mat-icon>menu</mat-icon></a>
         </div> -->
                <!-- <div class="aside-alert-link">
                    <a href="" class="new" data-toggle="tooltip" title="You have 2 unread messages"><i data-feather="message-square"></i></a>
                    <a href="" class="new" data-toggle="tooltip" title="You have 4 new notifications"><i data-feather="bell"></i></a>
                    <a href="" data-toggle="tooltip" title="Sign out"><i data-feather="log-out"></i></a>
                </div> -->
        <!-- <div class="aside-loggedin">
            <div class="upperborder"></div>
            <div class="d-flex align-items-center justify-content-center">
                <a href="" class="avatar avatar-xxl avatar-online"><img src="{{(logged_in_user && logged_in_user.avatar)? endpoint.apiUrl+''+logged_in_user.avatar :'./assets/avatar/avatar.png'}}" class="rounded-circle" alt=""></a>
            </div>
            <div class="aside-loggedin-user">
            <a href="#loggedinMenu" class="d-flex align-items-center justify-content-center" data-toggle="collapse">
                <h6 class="user tx-medium mg-b-0">{{(logged_in_user && logged_in_user.name) ? logged_in_user.name : 'User'}}</h6>
                <i data-feather="chevron-down"></i>
            </a>
            <p class="tx-color-03 mg-b-0 text-center email">{{(logged_in_user && logged_in_user.email) ? logged_in_user.email : ''}}</p>
            <p class="tx-color-03 tx-12 mt-1 mb-2 text-center"><span class="role">{{(logged_in_user && logged_in_user.role.name) ? logged_in_user.role.name : ''}}</span></p>
            <p class="text-center mt-1 mg-b-0">
                <span class="user-rating">
                    <span class="d-flex align-items-center"><mat-icon class="tx-14 tx-orange">star</mat-icon> <span> 4.5</span>
                    </span>
                </span>
            </p>
            </div>
            <div class="collapse" id="loggedinMenu">
            <ul class="nav nav-aside mg-b-0">
                <li class="nav-item"><a href="" class="nav-link"><i data-feather="edit"></i> <span>Edit Profile</span></a></li>
                <li class="nav-item"><a href="" class="nav-link"><i data-feather="user"></i> <span>View Profile</span></a></li>
                <li class="nav-item"><a href="" class="nav-link"><i data-feather="settings"></i> <span>Account Settings</span></a></li>
                <li class="nav-item"><a href="" class="nav-link"><i data-feather="help-circle"></i> <span>Help Center</span></a></li>
                <li class="nav-item"><a href="" class="nav-link"><i data-feather="log-out"></i> <span>Sign Out</span></a></li>
            </ul>
            </div>
        </div> -->

        <app-sidenav-list></app-sidenav-list>
        <!-- <ul class="nav nav-aside">
            <li class="nav-label">Main</li>
            <li class="nav-item active"><a [routerLink]="['/operation/dashboard']">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-grid"><rect x="3" y="3" width="7" height="7"></rect><rect x="14" y="3" width="7" height="7"></rect><rect x="14" y="14" width="7" height="7"></rect><rect x="3" y="14" width="7" height="7"></rect></svg>
                Dashboard</a>
            </li>
            <li class="nav-label mg-t-25">Sales</li>
            <li class="nav-item"><a href="dashboard-one.html" class="nav-link"><i data-feather="shopping-bag"></i> <span>Enquiry</span></a></li>
            <li class="nav-item with-sub">
                <a href="" class="nav-link"><i data-feather="user"></i> <span>User Pages</span></a>
                <ul>
                  <li><a href="page-profile-view.html">View Profile</a></li>
                  <li><a href="page-connections.html">Connections</a></li>
                  <li><a href="page-groups.html">Groups</a></li>
                  <li><a href="page-events.html">Events</a></li>
                </ul>
              </li>
            <li class="nav-item"><a href="dashboard-two.html" class="nav-link"><i data-feather="globe"></i> <span>Website Analytics</span></a></li>
            <li class="nav-item"><a href="dashboard-three.html" class="nav-link"><i data-feather="pie-chart"></i> <span>Cryptocurrency</span></a></li>
            <li class="nav-item"><a href="dashboard-four.html" class="nav-link"><i data-feather="life-buoy"></i> <span>Helpdesk Management</span></a></li>
            <li class="nav-label mg-t-25">Web Apps</li>
            <li class="nav-item"><a href="app-calendar.html" class="nav-link"><i data-feather="calendar"></i> <span>Calendar</span></a></li>
            <li class="nav-item"><a href="app-chat.html" class="nav-link"><i data-feather="message-square"></i> <span>Chat</span></a></li>
            <li class="nav-item"><a href="app-contacts.html" class="nav-link"><i data-feather="users"></i> <span>Contacts</span></a></li>
            <li class="nav-item"><a href="app-file-manager.html" class="nav-link"><i data-feather="file-text"></i> <span>File Manager</span></a></li>
            <li class="nav-item"><a href="app-mail.html" class="nav-link"><i data-feather="mail"></i> <span>Mail</span></a></li>

            <li class="nav-label mg-t-25">Pages</li>
            <li class="nav-item with-sub">
            <a href="" class="nav-link"><i data-feather="user"></i> <span>User Pages</span></a>
            <ul>
                <li><a href="page-profile-view.html">View Profile</a></li>
                <li><a href="page-connections.html">Connections</a></li>
                <li><a href="page-groups.html">Groups</a></li>
                <li><a href="page-events.html">Events</a></li>
            </ul>
            </li>
            <li class="nav-item with-sub">
            <a href="" class="nav-link"><i data-feather="file"></i> <span>Other Pages</span></a>
            <ul>
                <li><a href="page-timeline.html">Timeline</a></li>
            </ul>
            </li>
            <li class="nav-label mg-t-25">User Interface</li>
            <li class="nav-item"><a href="../../components" class="nav-link"><i data-feather="layers"></i> <span>Components</span></a></li>
            <li class="nav-item"><a href="../../collections" class="nav-link"><i data-feather="box"></i> <span>Collections</span></a></li>
        </ul> -->
    </div>
</div>
