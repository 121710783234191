<div class="content content-auth bg">
  <div class="pos-relative">
    <div class="sign-wrapper text-cenetr">
      <div class="wd-100p">
        <div class="navbar-brand">
          <!-- <div class="df-logo text-cenetr">
            <img src="assets/img/logo.png" class="logoImage">
          </div> -->
        </div>
        <div class="heading">
          <h1>Welcome</h1>
        </div>
        <img src="../../assets/img/user.png" alt="user" class="mb-3 mt-3">
        <p class="tx-color-03 tx-16 mg-b-20 text-center">
          Please Sign in to continue
        </p>
        <form [formGroup]="loginForm" (ngSubmit)="getLogIn()">
          <div>
            <mat-form-field class="form-field" appearance="outline">
              <mat-label>Username</mat-label>
            <input
              matInput
              formControlName="username"
              type="text"
              placeholder="Username"
            />
            <mat-icon matPrefix>person</mat-icon>
            <mat-error *ngIf="f.username.touched && f.username.invalid">
              Username is <strong>required</strong>
            </mat-error>
            </mat-form-field>
          </div>
          <div>
            <mat-form-field class="form-field" appearance="outline">
              <mat-label>Password</mat-label>
              <input
                matInput
                [type]="hide ? 'password' : 'text'"
                formControlName="password"
                type="password"
                placeholder="Password"
              />
              <mat-icon matPrefix>lock</mat-icon>
              <a mat-icon-button matSuffix (click)="hide = !hide" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide">
                <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
              </a>
              <mat-error *ngIf="f.password.touched && f.password.invalid">
                Password is <strong>required</strong>
              </mat-error>
            </mat-form-field>
            <!-- <div class="d-flex justify-content-between mg-b-5">
              <a (click)="forgotPassword()" routerLinkActive="router-link-active"  class="tx-13 tx-right forgotPass">Forgot password?</a>
            </div> -->
          </div>
          <button mat-raised-button class="btn btn-brand-02 btn-block" [disabled]="!loginForm.valid || progress_loader">Log In <mat-icon>trending_flat</mat-icon></button>
          <mat-progress-bar mode='indeterminate' *ngIf="progress_loader"></mat-progress-bar>
        </form>
      </div>
  </div>
    <!-- <div class="container mr-0 pr-0">
      <div class="media align-items-stretch justify-content-center ht-100p pos-relative">
            <div class="media-body align-items-center d-none d-lg-flex">
              <div class="mx-wd-600">
                <img src="assets/img/login-bg.jpg" class="img-fluid sideImage" alt="" />
              </div>
            </div>
            
      </div>
    </div> -->
  </div>
</div>
