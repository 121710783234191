import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment.prod';
import { AuthService } from 'src/services/auth-user/auth.service';
import { EndPointService } from 'src/services/end-point.service';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css']
})
export class SidebarComponent implements OnInit {
  contact_details = environment;
  isMinimized = true;
  isMaximized = false;
  logged_in_user: any;
  visible_header: boolean = false;

  constructor(public AuthService: AuthService, public endpoint: EndPointService,private location: Location) { }
  
  toggleMinimize() {
    this.isMinimized = !this.isMinimized;
    this.AuthService.setSideToggle(this.isMinimized);
  }
  toggleMaximize() {
    this.isMaximized = !this.isMaximized;
    this.AuthService.setSideToggle(this.isMaximized);
  }
  ngOnInit(): void {
    this.AuthService.getSideToggle().subscribe(val => {
      this.isMinimized = val;
    });

    this.AuthService.getLogin().subscribe(value=>{
      this.logged_in_user = value;
      console.log(this.logged_in_user);
    });

    this.AuthService.getheader().subscribe(value=>{
      if(this.location.path() !== "" && this.location.path().split('/')[1] !== "reset-password" && value){
        this.visible_header = true;
        // this.getNotifications();
        return;
      }
      if(typeof value !== 'boolean'){
        return;
      }
      this.visible_header = value;
    });
  }



  mouseEnterLeave(action){
    this.AuthService.setSideToggle(action);
  }
}
