import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { SnackBarService } from 'src/services/alert/snack-bar.service';
import { AuthService } from 'src/services/auth-user/auth.service';
import { EndPointService } from 'src/services/end-point.service';

@Component({
  selector: 'app-package-details',
  templateUrl: './package-details.component.html',
  styleUrls: ['./package-details.component.css']
})
export class PackageDetailsComponent implements OnInit {
  package: any;
  nights = [];
  packages = [];

  constructor(
    private authService:AuthService,
    private endpoint: EndPointService,
    private _snack_bar: SnackBarService,
    private active_route: ActivatedRoute,
  ) { }

  ngOnInit(): void {
    let package_id = Number(this.active_route.snapshot.paramMap.get("id"));
    if(package_id > 0){
      this.getCustomizedPackage(package_id);
    }
    this.getNight();
    this.getPackages();
  }

  getPackages(){
    this.endpoint.getData("admins/customised-package",{}).subscribe(
      (resp) => {
        if (resp.status && resp.message == 'success') {
          this.packages = resp.data;
          return;
        }
        this._snack_bar.error(resp.message);
      },
      (err) => {
        this._snack_bar.error("Error while fetching package.");
      }
    );
  }

  getCustomizedPackage(package_id){
    this.endpoint.getData("admins/"+package_id+"/customised-package",{}).subscribe(
      (resp) => {
        if (resp.status && resp.message == 'success') {
          this.package = resp.data;
          return;
        }
        this._snack_bar.error(resp.message);
      },
      (err) => {
        this._snack_bar.error("Error while fetching package.");
      }
    );
  }

  getNight(){
    this.endpoint.getData("admins/customised-package/night-list",{}).subscribe(
      (resp) => {
        if (resp.status && resp.message == 'success') {
          this.nights = resp.data;
          return;
        }
        this._snack_bar.error(resp.message);
      },
      (err) => {
        this._snack_bar.error("Error while fetching package.");
      }
    );
  }

  onPrint(){
    this.authService.header(false);
    setTimeout(function(authService){
      window.print();
      authService.header(true);
    }, 100, this.authService);
  }
}
