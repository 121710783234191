import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS } from "@angular/material-moment-adapter";
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from "@angular/material/core";
import * as _moment from "moment";

const moment =  _moment;
export const MY_FORMATS = {
  parse: {
    dateInput: "MMMM DD, YYYY"
  },
  display: {
    dateInput: "MMMM DD, YYYY",
    monthYearLabel: "MMM YYYY",
    dateA11yLabel: "LL",
    monthYearA11yLabel: "MMMM YYYY"
  }
};

@Component({
  selector: 'app-delete-dialog',
  templateUrl: './delete-dialog.component.html',
  styleUrls: ['./delete-dialog.component.css'],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },

    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS }
  ]
})
export class DeleteDialogComponent implements OnInit {
  holdBtn: boolean = false;
  message: any;
  hold = {
    hold_date: ""
  }
  is_hold =  false;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<DeleteDialogComponent>
  ) { }

  ngOnInit(): void {
    this.holdBtn = this.data != null && this.data.holdBtn;
    this.message = this.data != null && this.data.message.length > 0 ? this.data.message : 'Are you sure want to delete?'
  }

  onConfirmClick(): void {
    this.dialogRef.close(true);
  }

  onHoldConfirmClick(): void {
  const data = {
    hold:'hold',
    hold_till: this.hold.hold_date
  }
    this.dialogRef.close(data);
  }


  onDismiss(): void {
    this.dialogRef.close(false);
  }

  toggleChanged(event){
    this.is_hold = event.checked
  }
}
