<div class="app-filemgr">
    <div class="filemgr-wrapper">
        <div class="filemgr-sidebar">
            <div class="filemgr-sidebar-body">
              <div class="pd-t-20 pd-b-10 pd-x-10">
                <label class="tx-sans tx-uppercase tx-medium tx-10 tx-spacing-1 tx-color-03 pd-l-10">My Drive</label>
                <nav class="nav nav-sidebar tx-13">
                  <a href="" class="nav-link active"><i data-feather="folder"></i> <span>All Files</span></a>
                  <a href="" class="nav-link"><i data-feather="monitor"></i> <span>My Devices</span></a>
                  <a href="" class="nav-link"><i data-feather="clock"></i> <span>Recents</span></a>
                  <a href="" class="nav-link"><i data-feather="star"></i> <span>Important</span></a>
                  <a href="" class="nav-link"><i data-feather="trash"></i> <span>Deleted Files</span></a>
                </nav>
              </div>
              <div class="pd-10">
                <label class="tx-sans tx-uppercase tx-medium tx-10 tx-spacing-1 tx-color-03 pd-l-10">File Library</label>
                <nav class="nav nav-sidebar tx-13">
                  <a href="" class="nav-link"><i data-feather="file"></i> <span>Documents</span></a>
                  <a href="" class="nav-link"><i data-feather="image"></i> <span>Images</span></a>
                  <a href="" class="nav-link"><i data-feather="video"></i> <span>Videos</span></a>
                  <a href="" class="nav-link"><i data-feather="music"></i> <span>Audio</span></a>
                  <a href="" class="nav-link"><i data-feather="package"></i> <span>Zip Files</span></a>
                </nav>
              </div>
              <div class="pd-y-10 pd-x-20">
                <label class="tx-sans tx-uppercase tx-medium tx-10 tx-spacing-1 tx-color-03 mg-b-15">Storage Status</label>

                <div class="media">
                  <i data-feather="database" class="wd-30 ht-30"></i>
                  <div class="media-body mg-l-10">
                    <div class="tx-12 mg-b-4">10.2GB used of 50GB</div>
                    <div class="progress ht-3 mg-b-20">
                      <div class="progress-bar wd-15p" role="progressbar" aria-valuenow="15" aria-valuemin="0" aria-valuemax="100"></div>
                    </div>
                  </div><!-- media-body -->
                </div><!-- media -->
                <p class="tx-12">Get 2TB (2,000GB) of storage now and get 40% off. Offers ends soon. <a href="">Learn more</a></p>
              </div>
            </div><!-- filemgr-sidebar-body -->
          </div><!-- filemgr-sidebar -->
      <div class="filemgr-content">
        <div class="filemgr-content-body">
          <div class="pd-20 pd-lg-25 pd-xl-30">
            <h4 class="mg-b-15 mg-lg-b-25">All Files</h4>

            <label class="d-block tx-medium tx-10 tx-uppercase tx-sans tx-spacing-1 tx-color-03 mg-b-15">Recently Accessed Files</label>
            <div class="row row-xs">
                <div class="col-6 col-sm-4 col-md-3">
                    <mat-card>
                        <mat-card-content>
                            <img mat-card-image src="https://www.mountainedge.in/photoz/pkgImg/beachy_andaman.jpg" alt="Photo of a Shiba Inu">
                            <mat-card-title>Customize Packages</mat-card-title>
                            <mat-card-subtitle>All Packages with Itineraries</mat-card-subtitle>
                        </mat-card-content>
                        <mat-card-actions>
                          <a mat-button [routerLink]="['/files/customize-packages']">VIEW</a>
                        </mat-card-actions>
                    </mat-card>
                </div>
                <div class="col-6 col-sm-4 col-md-3">
                    <mat-card>
                        <mat-card-content>
                            <img mat-card-image src="https://www.mountainedge.in/photoz/pkgImg/celestial-andaman.jpg" alt="Photo of a Shiba Inu">
                            <mat-card-title>Promotions</mat-card-title>
                            <mat-card-subtitle>Website, Emailer, Social Media etc..</mat-card-subtitle>
                        </mat-card-content>
                        <mat-card-actions>
                          <a mat-button>VIEW</a>
                        </mat-card-actions>
                    </mat-card>
                </div>
            </div><!-- row -->

            <hr class="mg-y-40 bd-0">
          </div>
        </div><!-- filemgr-content-body -->
      </div><!-- filemgr-content -->

    </div><!-- filemgr-wrapper -->

    <div class="pos-fixed b-10 r-10">
      <div id="toast" class="toast bg-dark bd-0 wd-300" data-delay="3000" role="alert" aria-live="assertive" aria-atomic="true">
        <div class="toast-header bg-transparent bd-white-1">
          <h6 class="tx-white mg-b-0 mg-r-auto">Downloading</h6>
          <button type="button" class="ml-2 mb-1 close tx-normal tx-shadow-none" data-dismiss="toast" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="toast-body pd-10 tx-white">
          <h6 class="mg-b-0">Medical_Certificate.pdf</h6>
          <span class="tx-color-03 tx-11">1.2mb of 4.5mb</span>
          <div class="progress ht-5 mg-t-5">
            <div class="progress-bar wd-50p" role="progressbar" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
          </div>
        </div>
      </div><!-- toast -->
    </div>
</div>

