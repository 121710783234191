<!-- <div class="navbar navbar-header navbar-header-fixed navbar-brand">
  <a href="/" class="df-logo">Trip<span>zolo</span></a>
</div>
<mat-nav-list>
  <a mat-list-item routerLink="">
    <mat-icon>home</mat-icon><span class="nav-caption">Home</span>
  </a>
</mat-nav-list> -->

<mat-nav-list class="sidenavlist">
  <mat-list-item class="nav-item active">
      <a class="nav-link" [routerLink]="['/operation/dashboard']">
        <img src="../../../assets/img/dashboard.png" class="mr-3"><span>Dashboard</span></a>
  </mat-list-item>
  <mat-list-item class="nav-item nav-category">
    <div class="subheader tx-uppercase">Sales</div>
  </mat-list-item>
  <mat-accordion>
    <mat-expansion-panel [class.mat-elevation-z0]="true" [expanded]="true" class="mb-0">
        <mat-expansion-panel-header>
          <img src="../../../assets/img/ic-enquiry.png" class="mr-3"> Enquiry
        </mat-expansion-panel-header>
        <mat-nav-list class="p-0">
          <mat-nav-list class="sub-menu">
            <mat-list-item class="nav-item" routerLink="#">
                <a class="nav-link" [routerLink]="['/operation/enquiry']"><span>New Enquiry</span></a>
            </mat-list-item>
            <mat-list-item class="nav-item" routerLink="#">
                <a class="nav-link" [routerLink]="['/operation/enquiry-list']"><span>All Enquiries</span></a>
            </mat-list-item>
          </mat-nav-list>
        </mat-nav-list>
    </mat-expansion-panel>
    <mat-expansion-panel [class.mat-elevation-z0]="true">
        <mat-expansion-panel-header>
          <img src="../../../assets/img/ic-confimration.png" class="mr-3"> Confirmation
        </mat-expansion-panel-header>
        <mat-nav-list class="p-0">
          <mat-nav-list class="sub-menu">
            <mat-list-item class="nav-item" routerLink="#">
                <a class="nav-link" [routerLink]="['/operation/confirmation-list']"><span>All Confirmations</span></a>
            </mat-list-item>
            <mat-list-item class="nav-item" routerLink="#">
                <a class="nav-link" [routerLink]="['/operation/cancelled-confirmation']"><span>Cancelled Booking</span></a>
            </mat-list-item>
          </mat-nav-list>
        </mat-nav-list>
    </mat-expansion-panel>
  </mat-accordion>
  <mat-list-item class="nav-item nav-category">
    <div class="subheader tx-uppercase">Market</div>
  </mat-list-item>
  <mat-accordion>
    <mat-expansion-panel [class.mat-elevation-z0]="true" class="mb-0">
        <mat-expansion-panel-header>
          <img src="../../../assets/img/travel-agency.png" class="mr-3"> B2B
        </mat-expansion-panel-header>
        <mat-nav-list class="p-0">
          <mat-nav-list class="sub-menu">
            <mat-list-item class="nav-item" routerLink="#">
                <a class="nav-link" [routerLink]="['/admin/agency-enquiry-status']"><span>All Agencies</span></a>
            </mat-list-item>
            <mat-list-item class="nav-item" routerLink="#">
                <a class="nav-link" [routerLink]="['/admin/agency-enquiry-status']"><span>Agency Report</span></a>
            </mat-list-item>
          </mat-nav-list>
        </mat-nav-list>
    </mat-expansion-panel>
  </mat-accordion>
  <mat-list-item class="nav-item">
    <a class="nav-link" [routerLink]="['/admin/agency-enquiry-status']">
      <img src="../../../assets/img/hr.png" class="mr-3"> <span>B2C</span></a>
  </mat-list-item>

  <mat-list-item class="nav-item nav-category">
    <div class="subheader tx-uppercase">Report</div>
  </mat-list-item>
  <mat-list-item class="nav-item">
    <a class="nav-link" [routerLink]="['/operation/sales-report']">
      <img src="../../../assets/img/ic-sales.png" class="mr-3"> <span>Sales</span></a>
  </mat-list-item>
  <mat-list-item class="nav-item">
      <a class="nav-link" [routerLink]="['/operation/deposits']">
        <img src="../../../assets/img/deposit.png" class="mr-3"><span>Unapproved Deposits</span></a>
  </mat-list-item>
  <mat-list-item class="nav-item">
    <a class="nav-link" [routerLink]="['/operation/statements']">
      <img src="../../../assets/img/ic-confimration.png" class="mr-3"> <span>Statement</span></a>
  </mat-list-item>
  <mat-list-item class="nav-item">
      <a class="nav-link" [routerLink]="['/operation/invoice-list']">
        <img src="../../../assets/img/invoice.png" class="mr-3"><span>Invoices</span></a>
  </mat-list-item>
  <mat-list-item class="nav-item">
      <a class="nav-link" [routerLink]="['/operation/confirmation-report']">
        <img src="../../../assets/img/ic-confimration.png" class="mr-3"><span>Confirmation Report</span></a>
  </mat-list-item>
  <mat-list-item class="nav-item">
      <a class="nav-link" [routerLink]="['/operation/hotel-liability']">
        <img src="../../../assets/img/hotel-liabilities.png" class="mr-3"><span>Hotel Liabilities</span></a>
  </mat-list-item>

  <mat-list-item class="nav-item nav-category">
    <div class="subheader tx-uppercase">Inventory</div>
  </mat-list-item>
  <mat-list-item class="nav-item">
      <a class="nav-link" [routerLink]="['/operation/hotel/inventory']">
        <img src="../../../assets/img/inventory.png" class="mr-3"><span>Hotel</span></a>
  </mat-list-item>

  <mat-list-item class="nav-item nav-category" *ngIf="!AuthService.isCoordinator() && !AuthService.isDriver() && !AuthService.isGroundManager() && !AuthService.isAgent() && !AuthService.isOperation()">
    <div class="subheader tx-uppercase">Products</div>
  </mat-list-item>
  <mat-accordion *ngIf="!AuthService.isCoordinator() && !AuthService.isDriver() && !AuthService.isGroundManager() && !AuthService.isAgent() && !AuthService.isOperation()">
    <mat-expansion-panel [class.mat-elevation-z0]="true" class="mb-0">
        <mat-expansion-panel-header>
          <img src="../../../assets/img/itinerary.png" class="mr-3"> Itineraries
        </mat-expansion-panel-header>
        <mat-nav-list class="p-0">
          <mat-nav-list class="sub-menu">
            <mat-list-item class="nav-item">
                <a class="nav-link" [routerLink]="['/admin/tour-itinerary']"><span>New Itinerary</span></a>
            </mat-list-item>
            <mat-list-item class="nav-item" routerLink="#">
                <a class="nav-link" [routerLink]="['/admin/tour-itinerary-list']"><span>All Itineraries</span></a>
            </mat-list-item>
          </mat-nav-list>
        </mat-nav-list>
    </mat-expansion-panel>
    <mat-expansion-panel [class.mat-elevation-z0]="true" class="mb-0">
        <mat-expansion-panel-header>
          <img src="../../../assets/img/hotels.png" class="mr-3"> Hotels
        </mat-expansion-panel-header>
        <mat-nav-list class="p-0">
          <mat-nav-list class="sub-menu">
            <mat-list-item class="nav-item" routerLink="#">
                <a class="nav-link" [routerLink]="['/admin/hotel']"><span>New Hotel</span></a>
            </mat-list-item>
            <mat-list-item class="nav-item" routerLink="#">
                <a class="nav-link" [routerLink]="['/admin/hotel-list']"><span>All Hotels</span></a>
            </mat-list-item>
          </mat-nav-list>
        </mat-nav-list>
    </mat-expansion-panel>
    <mat-expansion-panel [class.mat-elevation-z0]="true" class="mb-0">
        <mat-expansion-panel-header>
          <img src="../../../assets/img/package-hotel.png" class="mr-3"> Package Hotel
        </mat-expansion-panel-header>
        <mat-nav-list class="p-0">
          <mat-nav-list class="sub-menu">
            <mat-list-item class="nav-item" routerLink="#">
                <a class="nav-link" [routerLink]="['/admin/package-hotel']"><span>New Hotel</span></a>
            </mat-list-item>
            <mat-list-item class="nav-item" routerLink="#">
                <a class="nav-link" [routerLink]="['/admin/package-hotel-list']"><span>All Hotels</span></a>
            </mat-list-item>
          </mat-nav-list>
        </mat-nav-list>
    </mat-expansion-panel>
    <mat-expansion-panel [class.mat-elevation-z0]="true" class="mb-0">
        <mat-expansion-panel-header>
          <img src="../../../assets/img/custom-package.png" class="mr-3"> Customised Package
        </mat-expansion-panel-header>
        <mat-nav-list class="p-0">
          <mat-nav-list class="sub-menu">
            <mat-list-item class="nav-item">
                <a class="nav-link" [routerLink]="['/admin/customized-package-form']"><span>New Package</span></a>
            </mat-list-item>
            <mat-list-item class="nav-item">
                <a class="nav-link" [routerLink]="['/files/file']"><span>All Packages</span></a>
            </mat-list-item>
          </mat-nav-list>
        </mat-nav-list>
    </mat-expansion-panel>
    <mat-expansion-panel [class.mat-elevation-z0]="true" class="mb-0">
        <mat-expansion-panel-header>
          <img src="../../../assets/img/ferries.png" class="mr-3"> Ferries
        </mat-expansion-panel-header>
        <mat-nav-list class="p-0">
          <mat-nav-list class="sub-menu">
            <mat-list-item class="nav-item">
                <a class="nav-link" [routerLink]="['/admin/ferry-ticket-rate']"><span>New Ferry Rate</span></a>
            </mat-list-item>
            <mat-list-item class="nav-item">
                <a class="nav-link" [routerLink]="['/admin/ferry-ticket-rate-list']"><span>Rate List</span></a>
            </mat-list-item>
          </mat-nav-list>
        </mat-nav-list>
    </mat-expansion-panel>
    <mat-list-item class="nav-item">
      <a class="nav-link" [routerLink]="['/admin/place']">
        <img src="../../../assets/img/add-place.png" class="mr-3"> <span>New Place</span>
      </a>
    </mat-list-item>
    <mat-list-item class="nav-item">
        <a class="nav-link" [routerLink]="['/admin/markups']">
          <img src="../../../assets/img/markup.png" class="mr-3 markup-ic"><span>Add Markup</span>
        </a>
    </mat-list-item>
  </mat-accordion>

  <mat-list-item class="nav-category" *ngIf="AuthService.isSuperAdmin()">
    <div class="subheader tx-uppercase">Master Report</div>
  </mat-list-item>
  <mat-list-item class="nav-item" *ngIf="AuthService.isSuperAdmin()">
    <a class="nav-link" [routerLink]="['/admin/sales-report']">
      <img src="../../../assets/img/ic-sales.png" class="mr-3"> <span>Sales</span>
    </a>
  </mat-list-item>
  <mat-list-item class="nav-item" *ngIf="AuthService.isSuperAdmin()">
    <a class="nav-link" [routerLink]="['/admin/hotel-purchase']">
      <img src="../../../assets/img/hotel-purchase.png" class="mr-3"> <span>Hotel Purchase</span>
    </a>
  </mat-list-item>
  <mat-list-item class="nav-item" *ngIf="AuthService.isSuperAdmin()">
    <a class="nav-link" [routerLink]="['/admin/vehicle-report']">
      <img src="../../../assets/img/car.png" class="mr-3"> <span>Vehicle</span>
    </a>
  </mat-list-item>
  <mat-list-item class="nav-item" *ngIf="AuthService.isSuperAdmin()">
    <a class="nav-link" [routerLink]="['/admin/ticket-report']">
      <img src="../../../assets/img/tickets.png" class="mr-3"> <span>Ticket</span>
    </a>
  </mat-list-item>
  <mat-list-item class="nav-item" *ngIf="AuthService.isSuperAdmin()">
    <a class="nav-link" [routerLink]="['/admin/agency-enquiry-status']">
      <img src="../../../assets/img/travel-agency.png" class="mr-3"> <span>Agency Report</span>
    </a>
  </mat-list-item>
  <mat-list-item class="nav-item" *ngIf="AuthService.isSuperAdmin()">
    <a class="nav-link" [routerLink]="['/accounts/hotel-sales-report']">
      <img src="../../../assets/img/bank-statement.png" class="mr-3"> <span>Hotel Payment Report</span>
    </a>
  </mat-list-item>

  <mat-list-item class="nav-item nav-category" *ngIf="AuthService.isSuperAdmin()">
    <div class="subheader tx-uppercase">Accounts</div>
  </mat-list-item>
  <mat-list-item class="nav-item" *ngIf="AuthService.isSuperAdmin()">
    <a class="nav-link" [routerLink]="['/accounts/deposit-payment-confirmation']">
      <img src="../../../assets/img/deposit.png" class="mr-3"> <span>Deposit Verification</span>
    </a>
  </mat-list-item>
  <mat-list-item class="nav-item" *ngIf="AuthService.isSuperAdmin()">
      <a class="nav-link" [routerLink]="['/accounts/credit-bank-statement']">
        <img src="../../../assets/img/bank-statement.png" class="mr-3"><span>Credited Bank Statement</span>
      </a>
  </mat-list-item>
  <mat-list-item class="nav-item" *ngIf="AuthService.isSuperAdmin()">
      <a class="nav-link" [routerLink]="['accounts/credit-note-list']">
        <img src="../../../assets/img/payment.png" class="mr-3"><span>Credit Note</span>
      </a>
  </mat-list-item>
  <mat-list-item class="nav-item" *ngIf="AuthService.isSuperAdmin()">
      <a class="nav-link" [routerLink]="['/admin/markups']">
        <img src="../../../assets/img/gst.png" class="mr-3"><span>GST</span>
      </a>
  </mat-list-item>
  <mat-accordion *ngIf="AuthService.isSuperAdmin()">
    <mat-expansion-panel [class.mat-elevation-z0]="true" class="mb-0">
        <mat-expansion-panel-header>
          <img src="../../../assets/img/invoice.png" class="mr-3"> Invoice
        </mat-expansion-panel-header>
          <mat-nav-list class="sub-menu">
            <mat-list-item class="nav-item" routerLink="#">
                <a class="nav-link" [routerLink]="['accounts/invoice-list']"><span>All Invoice</span></a>
            </mat-list-item>
            <mat-list-item class="nav-item" routerLink="#">
                <a class="nav-link" [routerLink]="['accounts/invoice-requests']"><span>Invoice Request</span></a>
            </mat-list-item>
          </mat-nav-list>
    </mat-expansion-panel>
  </mat-accordion>
  <mat-accordion *ngIf="AuthService.isSuperAdmin()">
    <mat-expansion-panel [class.mat-elevation-z0]="true" class="mb-0">
      <mat-expansion-panel-header>
        <img src="../../../assets/img/hr.png" class="mr-3"> Vendor Registration
      </mat-expansion-panel-header>
        <mat-nav-list class="sub-menu">
          <mat-list-item class="nav-item">
              <a class="nav-link" [routerLink]="['/accounts/vendors-list']"><span>All Vendors</span></a>
          </mat-list-item>
          <mat-list-item class="nav-item">
              <a class="nav-link" [routerLink]="['/accounts/vendor-registration']"><span>Add New Vendor</span></a>
          </mat-list-item>
        </mat-nav-list>
    </mat-expansion-panel>
  </mat-accordion>

  <mat-list-item class="nav-item nav-category" *ngIf="AuthService.isSuperAdmin()">
    <div class="subheader tx-uppercase">HR</div>
  </mat-list-item>
  <mat-accordion *ngIf="AuthService.isSuperAdmin()">
    <mat-expansion-panel [class.mat-elevation-z0]="true" class="mb-0">
      <mat-expansion-panel-header>
        <img src="../../../assets/img/hr.png" class="mr-3"> Exmployees
      </mat-expansion-panel-header>
      <!-- <mat-nav-list class="p-0"> -->
        <mat-nav-list class="sub-menu">
          <mat-list-item class="nav-item">
              <a class="nav-link" [routerLink]="['/accounts/manage/user-list']"><span>All Employees</span></a>
          </mat-list-item>
          <mat-list-item class="nav-item">
              <a class="nav-link" [routerLink]="['/attendance']"><span>Leaves</span></a>
          </mat-list-item>
          <mat-list-item class="nav-item">
              <a class="nav-link" [routerLink]="['/attendance']"><span>Attendance</span></a>
          </mat-list-item>
        </mat-nav-list>
      <!-- </mat-nav-list> -->
    </mat-expansion-panel>
    <mat-expansion-panel [class.mat-elevation-z0]="true" class="mb-0">
      <mat-expansion-panel-header>
        <img src="../../../assets/img/money.png" class="mr-3"> Payroll
      </mat-expansion-panel-header>
        <mat-nav-list class="sub-menu">
          <mat-list-item class="nav-item">
              <a class="nav-link" [routerLink]="['hr/employee-salary']"><span>Employee Salary</span></a>
          </mat-list-item>
          <mat-list-item class="nav-item">
              <a class="nav-link" [routerLink]="['hr/payslip']"><span>PaySlip</span></a>
          </mat-list-item>
          <mat-list-item class="nav-item">
              <a class="nav-link" [routerLink]="['hr/payslip']"><span>Appointment Letter</span></a>
          </mat-list-item>
          <mat-list-item class="nav-item">
              <a class="nav-link" [routerLink]="['hr/payslip']"><span>Experience Letter</span></a>
          </mat-list-item>
        </mat-nav-list>
    </mat-expansion-panel>
  </mat-accordion>

  <!-- <mat-list-item class="nav-item nav-category" *ngIf="AuthService.isSuperAdmin()">
    <div class="subheader tx-uppercase">Admin</div>
  </mat-list-item>
  <mat-list-item class="nav-item" *ngIf="AuthService.isSuperAdmin()">
    <a class="nav-link" [routerLink]="['/']">
      <img src="../../../assets/img/assets.png" class="mr-3"> <span>Assets</span>
    </a>
  </mat-list-item>
  <mat-list-item class="nav-item" *ngIf="AuthService.isSuperAdmin()">
    <a class="nav-link" [routerLink]="['/']">
      <img src="../../../assets/img/activities.png" class="mr-3"> <span>Activities</span>
    </a>
  </mat-list-item> -->

  <mat-list-item class="nav-item nav-category" *ngIf="AuthService.isSuperAdmin()">
    <div class="subheader tx-uppercase">Liabilities</div>
  </mat-list-item>
  <mat-list-item class="nav-item" *ngIf="AuthService.isSuperAdmin()">
    <a class="nav-link" [routerLink]="['/admin/liability-payment']">
      <img src="../../../assets/img/hotel-liabilities.png" class="mr-3"> <span>Hotel Liabilities</span>
    </a>
  </mat-list-item>
  <mat-list-item class="nav-item" *ngIf="AuthService.isSuperAdmin()">
    <a class="nav-link" [routerLink]="['/admin/additional-liability-payment']">
      <img src="../../../assets/img/hotel-liabilities.png" class="mr-3"> <span>Additional Hotel Liabilities</span>
    </a>
  </mat-list-item>
  <mat-list-item class="nav-item" *ngIf="AuthService.isSuperAdmin()">
      <a class="nav-link" [routerLink]="['/admin/vehicle-liability-payment']">
        <img src="../../../assets/img/car.png" class="mr-3"><span>Vehicle & Ticket</span>
      </a>
  </mat-list-item>
  <mat-list-item class="nav-item" *ngIf="AuthService.isSuperAdmin()">
      <a class="nav-link" [routerLink]="['admin/other-liability']">
        <img src="../../../assets/img/liability.png" class="mr-3"><span>Other Liabilities</span>
      </a>
  </mat-list-item>

  <mat-list-item class="nav-item nav-category" *ngIf="AuthService.isSuperAdmin()">
    <div class="subheader tx-uppercase">Ground Management</div>
  </mat-list-item>
  <mat-list-item class="nav-item" *ngIf="AuthService.isSuperAdmin()">
    <a class="nav-link" [routerLink]="['/admin/manage/coordinator/confirmation-list']">
      <img src="../../../assets/img/coordination.png" class="mr-3"> <span>Coordinator Confirmations</span>
    </a>
  </mat-list-item>
  <mat-list-item class="nav-item" *ngIf="AuthService.isSuperAdmin()">
    <a class="nav-link" [routerLink]="['/admin/manage/confirmation-list']">
      <img src="../../../assets/img/ic-confimration.png" class="mr-3"> <span>All Confirmations</span>
    </a>
  </mat-list-item>
  <mat-list-item class="nav-item" *ngIf="AuthService.isSuperAdmin()">
      <a class="nav-link" [routerLink]="['/admin/manage/driver-tour-list']">
        <img src="../../../assets/img/car.png" class="mr-3"><span>Driver Tour list</span>
      </a>
  </mat-list-item>
  <mat-list-item class="nav-item" *ngIf="AuthService.isSuperAdmin()">
      <a class="nav-link" [routerLink]="['/admin/vehicles/inventory']">
        <img src="../../../assets/img/logistic.png" class="mr-3"><span>Logistic Management</span>
      </a>
  </mat-list-item>

  <mat-list-item class="nav-item nav-category" *ngIf="AuthService.isSuperAdmin() && AuthService.isCoordinator()">
    <div class="subheader tx-uppercase">Allocation</div>
  </mat-list-item>
  <mat-list-item class="nav-item" *ngIf="AuthService.isSuperAdmin() && AuthService.isCoordinator()">
      <a class="nav-link" [routerLink]="['/coordinator']">
        <img src="../../../assets/img/ic-confimration.png" class="mr-3"><span>Coordinator confirmations</span>
      </a>
  </mat-list-item>

  <mat-list-item class="nav-item nav-category" *ngIf="AuthService.isSuperAdmin() && AuthService.isDriver()">
    <div class="subheader tx-uppercase">Driver</div>
  </mat-list-item>
  <mat-list-item class="nav-item" *ngIf="AuthService.isSuperAdmin() && AuthService.isDriver()">
      <a class="nav-link" [routerLink]="['/drivers/tour-allocation']" *ngIf="AuthService.isSuperAdmin()">
        <img src="../../../assets/img/car.png" class="mr-3"><span>Tour List</span>
      </a>
  </mat-list-item>

  <mat-list-item class="nav-item nav-category" *ngIf="AuthService.isGroundManager() || AuthService.isGroundOperation()">
    <div class="subheader tx-uppercase">Ground Manager</div>
  </mat-list-item>
  <mat-list-item class="nav-item" *ngIf="AuthService.isGroundManager() || AuthService.isGroundOperation()">
      <a class="nav-link" [routerLink]="['/ground-manager/confirmation-list']">
        <img src="../../../assets/img/ic-confimration.png" class="mr-3"><span>All Confirmations</span>
      </a>
  </mat-list-item>
  <mat-list-item class="nav-item" *ngIf="AuthService.isGroundManager() || AuthService.isGroundOperation()">
      <a class="nav-link" [routerLink]="['/ground-manager/ground-payments']">
        <img src="../../../assets/img/bank-statement.png" class="mr-3"><span>Ground Payment</span>
      </a>
  </mat-list-item>
  <mat-list-item class="nav-item" *ngIf="AuthService.isGroundManager() || AuthService.isGroundOperation()">
      <a class="nav-link" [routerLink]="['/ground-manager/coordiator/confirmation-list']">
        <img src="../../../assets/img/ic-confimration.png" class="mr-3"><span>Coordinator Confirmations</span>
      </a>
  </mat-list-item>
  <mat-list-item class="nav-item" *ngIf="AuthService.isGroundManager() || AuthService.isGroundOperation()">
      <a class="nav-link" [routerLink]="['/ground-manager/drivers/tour-allocation']">
        <img src="../../../assets/img/car.png" class="mr-3"><span>Driver Tour list</span>
      </a>
  </mat-list-item>
  <mat-list-item class="nav-item" *ngIf="AuthService.isGroundManager() || AuthService.isGroundOperation()">
      <a class="nav-link" [routerLink]="['/accounts/vendors-list']">
        <img src="../../../assets/img/hr.png" class="mr-3"><span>All Vendors</span>
      </a>
  </mat-list-item>

</mat-nav-list>

<mat-nav-list>

  <!-- <mat-card>
    <div class="navbar-brand">
      <div class="navbar-brand">
        <a [routerLink]="['/operation/dashboard']" class="df-logo">
          <img src="{{contact_details.LOGO}}" class="logoImage">
        </a>
      </div>
      <a [routerLink]="['/operation/dashboard']" class="df-logo">ME<span>Group</span></a>
    </div>
  </mat-card> -->

  <!-- <mat-tree [dataSource]="dataSource" [treeControl]="treeControl" class="example-tree menus">
    <mat-tree-node *matTreeNodeDef="let node" >
      <mat-list-item mat-icon-button [routerLink]="[node.navigate]" matTreeNodeToggle>
        <mat-icon *ngIf="node.icon">{{node.icon}}</mat-icon> {{node.name}}
      </mat-list-item>
    </mat-tree-node>

    <mat-nested-tree-node *matTreeNodeDef="let node; when: hasChild">
          <mat-list-item mat-icon-button matTreeNodeToggle
                  [attr.aria-label]="'Toggle ' + node.name">
            <mat-icon class="mat-icon-rtl-mirror">
              {{treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'}}
            </mat-icon>
            <mat-icon *ngIf="node.icon">{{node.icon}}</mat-icon> {{node.name}}
          </mat-list-item>

        <div [class.example-tree-invisible]="!treeControl.isExpanded(node)" role="group">
          <ng-container matTreeNodeOutlet></ng-container>
      </div>
    </mat-nested-tree-node>
  </mat-tree> -->
</mat-nav-list>
