import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { SnackBarService } from 'src/services/alert/snack-bar.service';
import { AuthService } from 'src/services/auth-user/auth.service';

@Injectable({
  providedIn: 'root'
})
export class AgentGuard implements CanActivate {
  constructor(
    private router: Router,
    public authService: AuthService,
    public _snack_bar: SnackBarService,
  ) { }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
      const user = this.authService.getLogin();
      if ((JSON.parse(localStorage.getItem("trip-zolo-crm")) && JSON.parse(localStorage.getItem("trip-zolo-crm")).id > 0) || (user['_value'].id > 0 || user['_value'].includes() != [])) {
        const isAgent = this.authService.isAgent();
        if(!isAgent){
          this._snack_bar.error("Unauthorized Access.");
          this.router.navigate(['/']);
          return false;
        }
        return true;
      }else{
        this.router.navigate(['/'], { queryParams: { returnUrl: state.url }});
          return false;
      }
  }

}
