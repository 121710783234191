import { Component } from '@angular/core';
import { SnackBarService } from 'src/services/alert/snack-bar.service';
import { AuthService } from 'src/services/auth-user/auth.service';
import { EndPointService } from 'src/services/end-point.service';
import { Location } from '@angular/common';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { PermissionService } from 'src/services/permission/permission.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'tripzolo-crm';
  isMinimized = true;

  constructor(
    private endpoint: EndPointService,
    public AuthService: AuthService,
    public _snack_bar: SnackBarService,
    public location: Location,
    private http: HttpClient,
    private route: Router,
    private modulePermissionsService: PermissionService
  ){
    if(this.location.path() != '' && this.location.path().split('/')[1] !== "reset-password"){
    this.resetUserCredentials();
    }
  }

  toggleMinimize() {
    this.isMinimized = !this.isMinimized;
    this.AuthService.setSideToggle(this.isMinimized);
  }

  async resetUserCredentials(){
    let apiUrl = this.endpoint.apiUrl;

    if(JSON.parse(localStorage.getItem('trip-zolo-crm')) == null){
      this.route.navigate(['/']);
      return;
    }
    let token = JSON.parse(localStorage.getItem('trip-zolo-crm')).token;
    let headers = new HttpHeaders({
      'Content-Type': 'application/json;charset=UTF-8',
      'Authorization': 'Bearer '+ token,
    });

    let httpData = await this.http.get(apiUrl+'users/current-user',{headers: headers}).toPromise();
    localStorage.setItem('trip-zolo-crm',JSON.stringify(httpData['data']));
    this.AuthService.setLogin(httpData['data']);
  }
  ngOnInit(): void {
    this.AuthService.getSideToggle().subscribe(val => {
      this.isMinimized = val;
    });
    this.getUserPermissions();
  }

  getUserPermissions(){
    this.endpoint.getData("permissions", {}).subscribe(
      (resp) => {
        if (resp.status && resp.message == 'success') {
          this.modulePermissionsService.setModulePermissions(resp.data);
          return;
        }
      },
      (err) => {
        this._snack_bar.error("Error while fetching user permission.");
      }
    );
  }
}
