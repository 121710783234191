import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { SnackBarService } from 'src/services/alert/snack-bar.service';
import { AuthService } from 'src/services/auth-user/auth.service';

@Injectable({
  providedIn: 'root'
})
export class LoginGuard implements CanActivate {

  constructor(
    private router: Router,
    public authService: AuthService,
    public _snack_bar: SnackBarService,
) { }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
      const user = this.authService.getLogin();
      if ((JSON.parse(localStorage.getItem("trip-zolo-crm")) && JSON.parse(localStorage.getItem("trip-zolo-crm")).id > 0 || (user['_value'] != false && (user['_value'].id > 0) || user['_value'].includes() != []))) {
        const isAdmin = this.authService.isAdmin();
        const isSuperAdmin = this.authService.isSuperAdmin();
        const isCoordinator = this.authService.isCoordinator();
        const isLogistic = this.authService.isLogistic();
        const isDriver = this.authService.isDriver();
        const isOperation = this.authService.isOperation();
        const isAgent = this.authService.isAgent();
        const isGroundManager = this.authService.isGroundManager();

        if(isDriver){
          this.router.navigate(['drivers/tour-allocation']);
          return false;
        }
        if(isCoordinator || isLogistic){
          this.router.navigate(['coordinator/tour-list']);
          return false;
        }
        if(isGroundManager){
          this.router.navigate(['ground-manager/confirmation-list']);
          return false;
        }
        if(isAgent){
          this.router.navigate(['agency']);
          return false;
        }
        if(isAdmin || isSuperAdmin || isOperation){
          this.router.navigate(['operation/dashboard'])
          return false;
        }
        return true;
      }else{
      this.authService.setLogin(false);
      // if(user['_value'] == false){
      //   this.router.navigate(['/'], { queryParams: { returnUrl: state.url }});
      // }
          return true;

      }
  }

}
