<!-- <app-header></app-header>
<main>
  <router-outlet></router-outlet>
</main> -->


<!-- <app-layout> -->
  <app-main-layout [ngClass]="{'minimize': isMinimized}" class="main">
  </app-main-layout>
  <!-- <mat-sidenav-container>
    <mat-sidenav #sidenav role="navigation">
      <app-sidenav-list (sidenavClose)="sidenav.close()" (click)="sidenav.toggle()"></app-sidenav-list>
    </mat-sidenav>
    <mat-sidenav-content class="content ht-100v pd-0">
      <app-header (sidenavToggle)="sidenav.toggle()"></app-header>
        <main>
          <router-outlet></router-outlet>
        </main>
      <app-footer></app-footer>
    </mat-sidenav-content>
  </mat-sidenav-container> -->
  <!-- </app-layout> -->
