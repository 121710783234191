import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AgentGuard } from './auth/agent.guard';
import { AuthGuard } from './auth/auth.guard';
import { CoordinatorGuard } from './auth/coordinator.guard';
import { DriverGuard } from './auth/driver.guard';
import { GroundManagerGuard } from './auth/ground-manager.guard';
import { GroundOperationGuard } from './auth/ground-operation.guard';
import { LoginGuard } from './auth/login.guard';
import { LogoutGuard } from './auth/logout.guard';
import { LoginComponent } from './login/login.component';
import { AttendanceComponent } from './operations/user/attendance/attendance/attendance.component';
import { ResetPasswordComponent } from './operations/user/reset-password/reset-password.component';

const routes: Routes = [
  { path: '', component: LoginComponent, canActivate: [LoginGuard]},
  { path: 'files', loadChildren: ()=>import('./file-manage-dashboard/file-manage-dashboard.module').then(mod => mod.FileManageDashboardModule)},
  { path: 'reset-password/:secure_key', component:ResetPasswordComponent },
  { path: 'attendance', component:AttendanceComponent },
  { path: 'admin', loadChildren: ()=>import('./admin/admin.module').then(mod => mod.AdminModule), canActivate:[AuthGuard, LogoutGuard]},
  { path: 'operation', loadChildren: ()=>import('./operations/operation.module').then(mod => mod.OperationModule), canActivate:[AuthGuard, LogoutGuard]},
  { path: 'pdf', loadChildren: ()=>import('./operations/operation.module').then(mod => mod.OperationModule), canActivate:[AuthGuard, LogoutGuard]},
  { path: 'accounts', loadChildren: ()=>import('./accounts/accounts.module').then(mod => mod.AccountsModule), canActivate:[AuthGuard, LogoutGuard]},
  { path: 'hr', loadChildren: ()=>import('./hr/hr.module').then(mod => mod.HrModule), canActivate:[AuthGuard, LogoutGuard]},
  { path: 'coordinator', loadChildren: ()=>import('./coordinator/coordinator.module').then(mod => mod.CoordinatorModule), canActivate:[CoordinatorGuard, LogoutGuard]},
  { path: 'drivers', loadChildren: ()=>import('./driver/driver.module').then(mod => mod.DriverModule), canActivate:[DriverGuard, LogoutGuard]},
  { path: 'agency', loadChildren: ()=>import('./agent/agent.module').then(mod => mod.AgentModule), canActivate:[AgentGuard, LogoutGuard]},
  { path: 'ground-manager', loadChildren: ()=>import('./ground-manager/ground-manager.module').then(mod => mod.GroundManagerModule), canActivate:[GroundManagerGuard, LogoutGuard]},
  { path: 'ground-operation', loadChildren: ()=>import('./ground-manager/ground-manager.module').then(mod => mod.GroundManagerModule), canActivate:[GroundOperationGuard, LogoutGuard]}
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    enableTracing: false,
    scrollPositionRestoration: 'top'
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
