import { Component, forwardRef, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { SnackBarService } from 'src/services/alert/snack-bar.service';
import { EndPointService } from 'src/services/end-point.service';
import { CalendarOptions, Calendar } from '@fullcalendar/core';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import { FullCalendarComponent } from '@fullcalendar/angular';

@Component({
  selector: 'app-attendance',
  templateUrl: './attendance.component.html',
  styleUrls: ['./attendance.component.css']
})
export class AttendanceComponent implements OnInit {
  @ViewChild('calendar') calendarComponent: FullCalendarComponent;
  attandance: any;
  attandance_data = [];
  attandance_loader: boolean = false;
  todayDate = new Date;
  calendarApi:  any;
  Events = [];
  calendarOptions: CalendarOptions;

  constructor(
    private endpoint:EndPointService,
    private navigate: Router,
    public _snack_bar: SnackBarService,
    private dialog: MatDialog
  ) { }

  ngOnInit(): void {
    this.getAttendance();
    this.getAttendanceByMonth(this.todayDate.getMonth()+1, this.todayDate.getFullYear());

    forwardRef(() => Calendar);
    this.calendarOptions = {
    plugins: [dayGridPlugin, interactionPlugin],
      initialView: 'dayGridMonth',
      dateClick: this.onDateClick.bind(this),
      events: this.attandance_data,//[{title: 'Event name', start: '2021-07-01'}],
      editable: true,
      customButtons: {
        prev: {
        text: '< Pre',
        click: this.preMonth.bind(this)
        },
        next: {
        text: 'Next >',
        click: this.nextMonth.bind(this)
        },
        today: {
          text: 'Today',
          click: this.currentMonth.bind(this)
          }
      }
  }
}

  nextMonth() {
    this.calendarApi = this.calendarComponent.getApi();
    this.calendarApi.next();
    this.getAttendanceByMonth(new Date(this.calendarApi.currentData.currentDate).getMonth()+1, new Date(this.calendarApi.currentData.currentDate).getFullYear());
  }

  currentMonth(){
    this.calendarApi = this.calendarComponent.getApi();
    this.calendarApi.today();
    this.getAttendanceByMonth(new Date(this.calendarApi.currentData.currentDate).getMonth()+1, new Date(this.calendarApi.currentData.currentDate).getFullYear());
  }

  preMonth() {
    this.calendarApi = this.calendarComponent.getApi();
    this.calendarApi.prev();
    this.getAttendanceByMonth(new Date(this.calendarApi.currentData.currentDate).getMonth()+1, new Date(this.calendarApi.currentData.currentDate).getFullYear());
  }

  onDateClick(res) {
    alert('Clicked on date : ' + res.dateStr)
  }

  onAttendance(){
    this.attandance_loader = true;
    this.endpoint.postData('users/login/attendance',{}).subscribe(resp => {
      if (resp.status && resp.message == "success") {
          this.attandance = resp.data;
          this._snack_bar.success((this.attandance.status === 1)? 'Successfully check in' : 'Successfully check out');
          this.getAttendanceByMonth(this.todayDate.getMonth()+1, this.todayDate.getFullYear());
          this.attandance_loader = false;
          return;
      }
      this.attandance_loader = false;
      this._snack_bar.error(resp.message);
    }, err => {
      this._snack_bar.error('Oops :)\nError while attending.');
      this.attandance_loader = false;
    });
  }

  getAttendance(){
    this.endpoint.getData('users/login/attendance', {}).subscribe(resp => {
      if (resp.status && resp.message == "success") {
          this.attandance = resp.data == null ? {} : resp.data;
          return;
      }
      this._snack_bar.error(resp.message);
    }, err => {
      this._snack_bar.error('Oops :)\nError while fetching attending.');
    });
  }


  getAttendanceByMonth(month, year){
    const postData = {
      month: month,
      year: year
    }
    this.endpoint.getData('users/attendance/month/attendance', postData).subscribe(resp => {
      if (resp.status && resp.message == "success") {
          this.attandance_data = resp.data;
          this.calendarComponent.options.events = this.attandance_data;
          return;
      }
      this._snack_bar.error(resp.message);
    }, err => {
      this._snack_bar.error('Oops :)\nError while fetching attending.');
    });
  }


}
