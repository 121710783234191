import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from 'src/services/auth-user/auth.service';

@Injectable({
  providedIn: 'root'
})
export class LogoutGuard implements CanActivate {

  constructor(
      private router: Router,
      public authService: AuthService
  ){}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
      const logger = localStorage.getItem('trip-zolo-crm');
      if (logger) {
        this.authService.header(true)
        // logged in so return true
        return true;
    }

    // not logged in so redirect to login page with the return url and return false
    // { queryParams: { returnUrl: state.url }}
    this.authService.header(false);
    this.router.navigate(['/']);
    return false;
  }

}
