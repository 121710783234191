import { Component, OnInit, ViewChild } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import {MatTreeNestedDataSource} from '@angular/material/tree'
import {NestedTreeControl} from '@angular/cdk/tree';
import { environment } from 'src/environments/environment.prod';
import { AuthService } from 'src/services/auth-user/auth.service';
interface Menu {
  name?: string;
  icon?: string;
  navigate?: string;
  children?: Menu[];
}
const TREE_DATA: Menu[] = [
{
  name: 'Products',
  children: [
  {
    name: 'Itinerary',
    icon: 'transfer_within_a_station',
    children: [
      {
      name: 'New Itinerary',
      navigate: '/admin/tour-itinerary'
      },
      {name: 'Itinerary list', navigate: '/admin/tour-itinerary-list'},
    ]
  },
  {
    name: 'Hotel',
    icon: 'hotel',
    children: [
      {name: 'New hotel', navigate: '/admin/hotel'},
      {name: 'Hotel list', navigate: '/admin/hotel-list'},
    ]
  },
  {
    name: 'Package Hotel',
    icon: 'hotel',
    children: [
      {name: 'New package hotel', navigate: '/admin/package-hotel'},
      {name: 'Package hotel list', navigate: '/admin/package-hotel-list'},
    ]
  },
  {
    name: 'Ferry',
    icon: 'directions_boat',
    children: [
      {name: 'New Ferry rate', navigate: '/admin/ferry-ticket-rate'},
      {name: 'Ferry rate list', navigate: '/admin/ferry-ticket-rate-list'},
    ]
  },
  {
    name: 'Place',
    icon: 'location_on',
    children: [
      {name: 'Add update place', navigate: '/admin/place'}
    ]
  },
]
},
{
  name: 'Reports',
  children: [
  {
    name: 'Sales',
    icon: 'sell',
    navigate: '/admin/sales-report'
  },
  {
    name: 'Hotel Purchase',
    icon: 'hotel',
    navigate: '/admin/hotel-purchase'
  },
  {
    name: 'Vehicle',
    icon: 'commute',
    navigate: '/admin/vehicle-report'
  },
  {
    name: 'Ticket',
    icon: 'local_activity',
    navigate: '/admin/ticket-report'
  },
  {
    name: 'Agency report',
    icon: 'person',
    navigate: '/admin/agency-enquiry-status'
  }
  ]
},
{
  name: 'Accounts',
  children: [
  {
    name: 'Deposit verification',
    icon: 'money',
    navigate: '/accounts/deposit-payment-confirmation'
  },
  {
    name: 'Credited bank statement',
    icon: 'list',
    navigate: '/accounts/credit-bank-statement'
  },
  {
    name: 'Employee',
    icon: 'badge',
    navigate: '/accounts/employee-payment'
  },
  {
    name: 'Invoice',
    icon: 'receipt_long',
    navigate: '/accounts/invoice-list'
  },
  {
    name: 'User',
    icon: 'person_add_alt',
    children: [
      {name: 'New User', navigate: '/accounts/manage/user'},
      {name: 'User list', navigate: '/accounts/manage/user-list'},
    ]
  }
]
},
{
  name: 'Liability',
  children: [
  {
    name: 'Hotel liability',
    icon: 'money_off',
    navigate: '/admin/liability-payment'
  },
  {
    name: 'Vehicle liability',
    icon: 'money_off',
    navigate: '/admin/vehicle-liability-payment'
  },
  {
    name: 'Other liability',
    icon: 'money_off',
    navigate: '/admin/other-liability'
  }
]
},
{
  name: 'Operations',
  children: [
  {
    name: 'Enqury',
    icon: 'help_center',
    children: [
      {name: 'New Enquiry', navigate: '/operation/enquiry'},
      {name: 'Enquiry list', navigate: '/operation/enquiry-list'},
    ]
  },
  {
    name: 'Confirmations',
    icon: 'check_circle',
    children: [
      {name: 'Confirmation list', navigate: '/operation/confirmation-list'},
      {name: 'Cancelled confirmation', navigate: '/operation/cancelled-confirmation'},
    ]
  },
  {
    name: 'Agency',
    icon: 'person',
    children: [
      {name: 'New Agency', navigate: '/operation/agency'},
      {name: 'Agency list', navigate: '/operation/agency-list'},
    ]
  },
  {
    name: 'Sales report',
    icon: 'sell',
    children: [
      {name: 'Sales report', navigate: '/operation/sales-report'},
    ]
  }
]
}
];

@Component({
  selector: 'app-sidenav-list',
  templateUrl: './sidenav-list.component.html',
  styleUrls: ['./sidenav-list.component.css']
})
export class SidenavListComponent implements OnInit {
  contact_details = environment;
  treeControl = new NestedTreeControl<Menu>(node => node.children);
  dataSource = new MatTreeNestedDataSource<Menu>();
  @ViewChild('sidenav') sidenav: MatSidenav;
  isExpanded = true;
  showSubmenu: boolean = false;
  isShowing = [];
  showSubSubMenu: boolean = false;
  panelOpenState = false;

  mouseEnterLeave(isExpanded, i) {
    if (!isExpanded) {
      this.isShowing[i] = true;
    }else{
      this.isShowing[i] = false;
    }
  }
  constructor(public AuthService: AuthService) {
    this.dataSource.data = TREE_DATA;
  }
  hasChild = (_: number, node: Menu) => !!node.children && node.children.length > 0;


  ngOnInit(): void {
  }



}
